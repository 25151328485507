import styled from 'styled-components';

const CardWrapper = styled.div`
  min-width: 320px;
  max-width: 440px;
  width: 100%;
  height: 580px;
`;

const CardImage = styled.div<{ background?: string }>`
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.73) 100%
    ),
    url(${({ background }) => background});
  background-size: cover;
  height: 100%;
  padding: 30px 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > h3 {
      font-family: 'Open Sans', sans-serif;
      font-size: 32px;
      font-weight: 700;
      text-shadow: 0px 2px 4px rgba(17, 9, 43, 0.8);
      color: var(--white);
    }

    & > p {
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: 600;
      text-shadow: 0px 2px 4px rgba(17, 9, 43, 0.8);
      color: var(--white);
    }

    & > span {
      border: none;
      border-radius: 20px;
      padding: 8px 16px;
      background: rgb(17, 9, 43);
      background: linear-gradient(
        180deg,
        rgba(17, 9, 43, 0.7) 0%,
        rgba(17, 9, 43, 1) 50%,
        rgba(17, 9, 43, 0.7) 100%
      );
      color: var(--white);
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: 600;
      text-shadow: 0px 2px 4px rgba(17, 9, 43, 0.8);
      width: fit-content;
    }
  }
`;

export { CardWrapper, CardImage };

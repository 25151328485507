import styled, { css } from 'styled-components';

interface SubHeaderContainerProps {
  background: string;
}

const SubHeaderContainer = styled.div<SubHeaderContainerProps>`
  padding-top: 120px;
  margin-bottom: 32px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  ${(props) =>
    props.background &&
    css`
      background-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.45) 40%,
          transparent
        ),
        url(${props.background});
    `}
`;

const SubHeaderContentContainer = styled.div``;

export { SubHeaderContainer, SubHeaderContentContainer };

import React, { useState } from 'react';
import LoadingContext from 'src/contexts/LoadingContext';

const LoadingContextProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContextProvider;

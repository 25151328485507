import React, { useCallback, useMemo } from 'react';
import useVideos from 'src/pages/Videos/hooks/useVideos';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from 'src/assets/add-icon.svg';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import showErrorMessage from 'src/helpers/showErrorMessage';
import SystemError from 'src/models/error';
import { addToListContent } from 'src/services/contents';
import showSuccessMessage from 'src/helpers/showSuccessMessage';

const AddToListContainer = styled.span`
  button {
    background: none;
    outline: none;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: var(--secondary-color);

    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const AddToList: React.FC = () => {
  const { selectedContent, reloadData } = useVideos();

  const addedToList = useMemo(() => {
    return selectedContent?.content_user?.book_mark;
  }, [selectedContent]);

  const handleAddToList = useCallback(async () => {
    if (selectedContent?.content_id) {
      try {
        const shouldAddToList = !addedToList;

        await addToListContent(selectedContent?.content_id, shouldAddToList);

        showSuccessMessage(
          `Vídeo ${
            shouldAddToList ? 'adicionado ' : 'removido d'
          }a lista de favoritos!`
        );

        await reloadData();
      } catch (error) {
        showErrorMessage(error as SystemError);
      }
    }
  }, [addedToList, reloadData, selectedContent?.content_id]);

  return (
    <AddToListContainer>
      {!!selectedContent ? (
        <button type="button" onClick={handleAddToList}>
          {addedToList ? (
            <>
              <AiOutlineCheckCircle size={24} />
              <span>Adicionado a Lista</span>
            </>
          ) : (
            <>
              <AddIcon />
              <span>Minha Lista</span>
            </>
          )}
        </button>
      ) : (
        <></>
      )}
    </AddToListContainer>
  );
};

export default AddToList;

import styled from 'styled-components';

const StandardButton = styled.button`
  border: none;
  background-color: var(--primary-color);
  border-radius: 8px;
  color: var(--white);
  font-weight: 700;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  min-height: 56px;
  padding: 16px 24px;
  text-transform: uppercase;
`;

const Button = styled.button`
  background: none;
  outline: none;
  border: none;
  display: flex;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
`;

export { StandardButton, Button };

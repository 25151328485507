import React from 'react';
import { LoadingContainer } from './style';
import ReactLoading from 'react-loading';
import useLoading from 'src/hooks/useLoading';

const Loading: React.FC = () => {
  const { isLoading } = useLoading();

  return isLoading ? (
    <LoadingContainer>
      <ReactLoading type="bars" color="#fff" />
    </LoadingContainer>
  ) : (
    <></>
  );
};

export default Loading;

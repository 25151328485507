import React, { useEffect, useMemo, useRef } from 'react';
import { CarouselContentContainer } from './style';
import Content from 'src/models/content';
import useLanding from 'src/hooks/useLanding';
import Theme from 'src/models/theme';
import clamp from 'clamp-js';

interface ContentCarouselProps {
  landingContent?: Content;
}

const CarouselContent: React.FC<ContentCarouselProps> = ({
  landingContent,
}) => {
  const title = useRef<HTMLHeadingElement>(null);
  const description = useRef<HTMLParagraphElement>(null);
  const { contents } = useLanding();

  const contentThemes = useMemo(() => {
    const themes = (contents.filter((c) => c.flag === 'theme') ||
      []) as unknown as Theme[];
    if (themes?.length) {
      return themes.filter((t) =>
        (t.children || [])
          .map((c) => c.content_id)
          .includes(landingContent?.content_id!)
      );
    }

    return [] as Theme[];
  }, [contents, landingContent?.content_id]);

  useEffect(() => {
    if (title.current) {
      clamp(title.current, { clamp: 2 });
    }
  }, []);

  useEffect(() => {
    if (description.current) {
      clamp(description.current, { clamp: 3 });
    }
  }, []);

  return (
    <CarouselContentContainer background={landingContent?.images?.thumbnail}>
      <div>
        {contentThemes.map((t) => (
          <span key={t.content_id}>{t.name}</span>
        ))}
      </div>
      <div>
        <h3 ref={title}>{landingContent?.name}</h3>
        <p ref={description}>{landingContent?.description}</p>
      </div>
    </CarouselContentContainer>
  );
};

export default CarouselContent;

import httpClient from 'src/http-client';
import ContentUser from 'src/models/content-user';

interface ContentUsersFilters {
  book_mark?: boolean;
  relations?: string[];
}

const getContentUsers = async (
  filters?: ContentUsersFilters
): Promise<ContentUser[]> => {
  const contentUsers = (
    await httpClient.get<ContentUser[]>(`/content-users`, { params: filters })
  ).data;

  return contentUsers;
};

export { getContentUsers };

import React from 'react';
import { TextContainer } from './style';

const PlanText: React.FC = () => {
  return (
    <TextContainer className="max-content">
      <div>
        <h1>
          Mais de <span>5 mil vídeos</span> na área da saúde com os melhores
          profissionais e instituições do país.{' '}
        </h1>
      </div>
      <div>
        <p>
          Baixe seus vídeos favoritos e sempre tenha algo para assistir
          off-line.
        </p>
        <p>Tecnologia de ponta e vídeos em alta definição.</p>
      </div>
    </TextContainer>
  );
};

export default PlanText

import React, { useEffect, useRef } from 'react';
import trim from 'src/helpers/trim';
import styled from 'styled-components';

const PlayerSambavideosContainer = styled.div`
  iframe {
    width: 100%;
    height: 440px;

    @media (max-width: 1300px) {
      height: 360px;
    }

    @media (max-width: 768px) {
      height: 220px;
    }

    @media (max-width: 400px) {
      height: 160px;
    }
  }
`;

declare let SambaPlayer: any;

export interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

interface PlayerSambaVideosProps {
  contentReference: string;
  resume?: number;
  getEventListeners?: (eventListener: PlayerEventListener) => void;
}

const PlayerSambavideos: React.FC<PlayerSambaVideosProps> = ({
  contentReference,
  resume,
  getEventListeners,
}) => {
  const playerContainer = useRef<HTMLDivElement>(null);

  const configurePlayer = () => {
    const playerBaseUrl =
      process.env.REACT_APP_PLAYER_BASE_URL ||
      'https://fast.player.liquidplatform.com/pApiv2/embed/';

    const contentReferenceWithoutBaseUrl = trim(
      (contentReference || '').replace(playerBaseUrl, '') || '',
      '/'
    );

    if (contentReferenceWithoutBaseUrl) {
      const splittedReference = contentReferenceWithoutBaseUrl.split('/');
      if (splittedReference && splittedReference.length > 1) {
        const playerHash = `${splittedReference[0]}`.replace(/^\/+|\/+$/g, '');
        const midiaId = `${splittedReference[1]}`.replace(/^\/+|\/+$/g, '');

        SambaPlayer.clearAllMediaEvents();

        new SambaPlayer(playerContainer.current, {
          ph: playerHash,
          m: midiaId,
          playerParams: {
            enableShare: false,
            resume: resume || false,
          },
          events: {
            '*': getEventListeners,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (playerContainer && playerContainer.current) {
      if (Array.from(playerContainer.current.children).length > 0) {
        playerContainer.current.firstChild?.remove();
      }

      configurePlayer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentReference]);

  return (
    <PlayerSambavideosContainer
      ref={playerContainer}
    ></PlayerSambavideosContainer>
  );
};

export default PlayerSambavideos;

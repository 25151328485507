const secondsToTimeText = (seconds: number) => {
  let timeText = '';

  const hours = Math.floor(seconds / 3600);
  if (hours) {
    timeText += `${`${hours}`.padStart(2, '0')}:`;
  }

  const minutes = Math.floor((seconds % 3600) / 60);

  timeText += `${`${minutes}`.padStart(2, '0')}:`;

  const remainingSeconds = (seconds % 3600) % 60;

  timeText += `${`${remainingSeconds}`.padStart(2, '0')}`;

  return timeText;
};

export default secondsToTimeText;

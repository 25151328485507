import styled from 'styled-components';

interface InputContainerProps {
  flex: number;
  disabled: boolean;
}

const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: start;
  flex: ${props => props.flex};

  label {
    margin-left: 8px;
    color: var(--secondary-color);
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }

  input {
    background: ${props => props.disabled ? '#E1E3E6' : '#F5F8FA'};
    border: 1px solid #CFD2D4;
    height: 48px;
    width: 100%;
    border-radius: 30px;
    padding: 16px 24px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: var(--secondary-color);
    
    :focus {
      outline: 2px solid var(--primary-color);
    }
  }
`;

export { InputContainer };

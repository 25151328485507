import styled from 'styled-components';

const CertificatesContainer = styled.div`
  width: 100%;
`;

const CertificatesTitle = styled.h2`
  font-size: 2.4em;
  text-align: left;
  width: 100%;
  color: var(--secondary-color);
  margin-bottom: 24px;
`;

const CertificatesList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export { CertificatesContainer, CertificatesTitle, CertificatesList };

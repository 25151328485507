import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import DropdownHeader from './DropdownHeader';
import SearchComponent from './Search';
import {
  HeaderContainer,
  HeaderContent,
  HeaderLink,
  HeaderLinks,
  MobileMenuOpenButton,
} from './style';
import usePartner from 'src/hooks/usePartner';
import User from 'src/models/user';
import useWindowSize from 'src/hooks/useWindowSize';
import MobileMenu from './MobileMenu';
import useMobileMenu from 'src/hooks/useMobileMenu';
import { GiHamburgerMenu } from 'react-icons/gi';

const Header: React.FC = () => {
  const [user, setUser] = useState<undefined | User>();
  const { setOpened } = useMobileMenu();
  const { pathname } = useLocation();
  const { PartnerLink, navigate } = usePartner();
  const { windowWidth } = useWindowSize();

  const handleOpenMobileMenu = useCallback(() => {
    setOpened(true);
  }, [setOpened]);

  const getUser = useCallback(() => {
    const user = window.localStorage.getItem('manole-user')
      ? JSON.parse(window.localStorage.getItem('manole-user') || '{}')
      : undefined;
    setUser(user);
  }, []);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <HeaderContainer>
      <HeaderContent className="max-content">
        {!!user ? (
          <PartnerLink to={!!user ? `/home` : '/'}>
            <Logo />
          </PartnerLink>
        ) : (
          <Link to="/">
            <Logo />
          </Link>
        )}

        {windowWidth > 1000 ? (
          <>
            <HeaderLinks>
              <HeaderLink
                onClick={() => navigate('/home')}
                active={pathname.includes('/home')}
              >
                Início
              </HeaderLink>
              <HeaderLink
                onClick={() => navigate('/specialties')}
                active={
                  pathname.includes('/specialties') &&
                  !pathname.includes('/my-list')
                }
              >
                Especialidades
              </HeaderLink>
              <HeaderLink
                onClick={() => navigate('/my-list')}
                active={pathname.includes('/my-list')}
              >
                Minha lista
              </HeaderLink>
            </HeaderLinks>

            <SearchComponent />

            <DropdownHeader />
          </>
        ) : (
          <>
            <MobileMenuOpenButton onClick={handleOpenMobileMenu}>
              <GiHamburgerMenu size={32} color="#ddd" />
            </MobileMenuOpenButton>

            <MobileMenu />
          </>
        )}
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;

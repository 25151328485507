import styled, { createGlobalStyle } from 'styled-components';
import heroImage from '../../assets/heroImage.png';

interface ButtonProps {
  header?: boolean;
}

const GlobalStyles = createGlobalStyle`
  header {
    display: none !important;
  }
`;

const LandingHeader = styled.header`
  display: flex !important;
  justify-content: space-between;
  position: absolute;
  gap: 16px;
  width: 100%;
  padding: 27px 48px;
  right: 0;

  svg {
    path {
      fill: #fff;
    }
  }
`;

const LandingPageContainer = styled.div`
  width: 100%;
`;

const Button = styled.button<ButtonProps>`
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: var(--white);
  text-transform: uppercase;

  ${(props) =>
    props.header
      ? `
    background: none;
    border: 1px solid #FFF;
    border-radius: 6px;
    width: 212px;
    height: 48px;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 1px;
  `
      : `
    background-color: var(--primary-color);
    border: none;
    border-radius: 8px;
    font-size: 18px;
    padding: 16px 24px;
    max-width: 300px;
    min-height: 56px;
  `}
`;

const Hero = styled.div`
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(${heroImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 650px;

  .leftSideHero {
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 16px;
    color: var(--white);
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 24px;
    padding-top: 200px;
    padding-left: 100px;

    & > h1 {
      font-size: 56px;
      max-width: 800px;
      & > span {
        color: var(--primary-color);
        font-family: 'Lato', sans-serif;
        font-weight: 700;
      }
    }

    & > p {
      padding-bottom: 24px;
    }

    @media (max-width: 768px) {
      font-size: 1em;
      padding-left: 24px;

      h1 {
        font-size: 2.4em;
      }
    }
  }
`;

const SpecialistConatiner = styled.div`
  color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  & > h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    font-weight: 700;
  }

  & > div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;
  }
`;

const SignContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: var(--secondary-color);
  justify-content: space-between;
  padding: 100px 100px;

  & > h1 {
    width: 400px;
    font-family: 'Open Sans', sans-serif;
    font-size: 56px;
    font-weight: 700;
    padding-top: 24px;
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
  }

  @media (max-width: 1000px) {
    padding: 0 24px;
    justify-content: center;

    & > div {
      justify-content: center;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
`;

const MostViewedConatiner = styled.div`
  background-color: #e9e9e9;

  & > div {
    padding: 64px 0px 98px 100px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  & > h1 {
    color: var(--secondary-color);
    font-family: 'Open Sans', sans-serif;
    font-size: 36px;
    font-weight: 700;
  }

  @media (max-width: 1000px) {
    & > div {
      padding: 0 24px;
    }
  }
`;

const DevicesContainer = styled.div`
  background-color: #f2f2f2;
  color: var(--secondary-color);
  font-size: 32px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  padding: 170px 60px;
  position: relative;

  .rightSide {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 28%;
    left: 58%;
    gap: 48px;
    width: 24%;

    & > p {
      width: 330px;
    }
  }

  .store {
    display: flex;
    gap: 16px;
  }

  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div > img {
      height: auto;
      width: 100%;
    }

    .rightSide {
      position: static;
      width: 100%;
    }

    .store {
      flex-wrap: wrap;
    }
  }
`;

export {
  GlobalStyles,
  LandingHeader,
  Button,
  LandingPageContainer,
  Hero,
  SpecialistConatiner,
  MostViewedConatiner,
  SignContainer,
  DevicesContainer,
};

import React, { useCallback, useEffect, useState } from 'react';
import SelectOption from 'src/models/select-option';
import {
  SelectContainer,
  SelectedOption,
  SelectOptionContainer,
  SelectOptions,
} from './style';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as BlueUpArrowIcon } from 'src/assets/blue-up-arrow-icon.svg';
import BlueDownArrowIcon from '../BlueDownArrowIcon';

interface SelectProps {
  label: JSX.Element | string;
  options: SelectOption[];
  value: SelectOption | undefined;
  setValue: (selectOption: SelectOption | undefined) => void;
}

const Select: React.FC<SelectProps> = ({
  label,
  options,
  value: initialValue,
  setValue: setOuterValue,
}) => {
  const [value, setValue] = useState<SelectOption | undefined>();
  const [showOptions, setShowOptions] = useState(false);
  const [inputGuid] = useState(uuidv4());

  const handleSetValue = useCallback(
    (event: React.MouseEvent, opt: SelectOption | undefined) => {
      event.stopPropagation();
      event.preventDefault();

      setValue(opt);
      setOuterValue(opt);

      setShowOptions(false);
    },
    [setOuterValue]
  );

  const handleFocus = useCallback(() => {
    window.clearTimeout((window as any)[inputGuid]);
    setShowOptions(true);
  }, [inputGuid]);

  const handleBlur = useCallback(() => {
    (window as any)[inputGuid] = window.setTimeout(() => {
      setShowOptions(false);
    }, 150);
  }, [inputGuid]);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <SelectContainer>
      <label htmlFor={inputGuid}>
        <SelectedOption>
          <span className="label">{label}:</span>
          <span className="selected-option">{value?.label}</span>
          <span className="icon">
            {showOptions ? <BlueUpArrowIcon /> : <BlueDownArrowIcon />}
          </span>
        </SelectedOption>
        {showOptions && (
          <SelectOptions>
            {!!options.length &&
              options.map((opt, index) => (
                <SelectOptionContainer
                  key={index}
                  onClick={(event) => handleSetValue(event, opt)}
                >
                  {opt.label}
                </SelectOptionContainer>
              ))}
          </SelectOptions>
        )}
      </label>

      <input
        id={inputGuid}
        style={{ opacity: '0', width: 0, height: 0 }}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </SelectContainer>
  );
};

export default Select;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SubHeader from 'src/components/SubHeader';
import SubHeaderTitle from 'src/components/SubHeader/SubHeaderTitle';
import Breadcrumb from 'src/components/Breadcrumb';
import usePartner from 'src/hooks/usePartner';
import specialtiesBanner from 'src/assets/specialties.png';
import Specialty from 'src/models/specialty';
import { getSpecialties as getSpecialtiesService } from 'src/services/specialties';
import {
  SpecialtiesContainer,
  SpecialtiesList,
  SpecialtiesTitleAndOrderContainer,
} from './style';
import { SubHeaderDivider } from 'src/components/SubHeaderDivider';
import GoBack from 'src/components/GoBack';
import SpecialtyCard from 'src/components/SpecialtyCard';
import useLoading from 'src/hooks/useLoading';
import Select from 'src/components/Select';
import SelectOption from 'src/models/select-option';
import goToTop from 'src/helpers/go-to-top';
import removeAccentuation from 'src/helpers/remove-accentuation';
import Pagination from 'src/components/Pagination';

const orderOptions = [
  {
    label: 'Mais recentes',
    value: 'most_recent',
  },
  { label: 'Ordem alfabética', value: 'alpha' },
] as SelectOption[];

const firstPage = 1;

const numberOfItemsPerPage = 12;

const Specialties: React.FC = () => {
  const [page, setPage] = useState(firstPage);
  const [specialties, setSpecialties] = useState([] as Specialty[]);
  const [selectedOrder, setSelectedOrder] = useState('alpha');
  const { partner, PartnerLink } = usePartner();
  const { setIsLoading } = useLoading();

  const handleChangePage = useCallback((page: number) => {
    goToTop();
    setPage(page);
  }, []);

  const handleChangeOrder = useCallback(
    (order: SelectOption | undefined) => {
      handleChangePage(firstPage);
      setSelectedOrder((order || {}).value || 'alpha');
    },
    [handleChangePage]
  );

  const getSpecialties = useCallback(async () => {
    setIsLoading(true);

    const { data: specialties } = await getSpecialtiesService();
    setSpecialties(specialties);

    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    getSpecialties();
  }, [getSpecialties]);

  const specialtiesToShow = useMemo(() => {
    let result = [...(specialties || [])].slice(
      (page - 1) * numberOfItemsPerPage,
      page * numberOfItemsPerPage
    );

    if (selectedOrder === 'most_recent') {
      result = (result || []).sort((s1, s2) => {
        const s1Date = new Date(s1.created_at);
        const s2Date = new Date(s2.created_at);

        return s1Date.getTime() > s2Date.getTime() ? -1 : 1;
      });
    } else {
      result = (result || []).sort((s1, s2) => {
        return removeAccentuation(s1.name) > removeAccentuation(s2.name)
          ? -1
          : 1;
      });
    }

    return result;
  }, [page, selectedOrder, specialties]);

  return (
    <SpecialtiesContainer>
      <SubHeader
        background={partner?.banner?.specialties! || specialtiesBanner}
      >
        <Breadcrumb
          crumbs={[
            <PartnerLink className="home" to="/home">
              Início
            </PartnerLink>,
            <PartnerLink to="/specialties">Especialidades</PartnerLink>,
          ]}
        />
        <SubHeaderDivider />
        <GoBack />
        <SubHeaderTitle title={'Especialidades'} />
      </SubHeader>

      <SpecialtiesTitleAndOrderContainer className="max-content">
        <h2>Descubra aqui</h2>

        <Select
          label="Classificar por"
          options={orderOptions}
          value={orderOptions.find((o) => o.value === selectedOrder)}
          setValue={handleChangeOrder}
        />
      </SpecialtiesTitleAndOrderContainer>

      <SpecialtiesList className="max-content">
        {!!specialtiesToShow.length &&
          specialtiesToShow.map((specialty) => (
            <SpecialtyCard key={specialty.content_id} specialty={specialty} />
          ))}
      </SpecialtiesList>

      <Pagination
        pageCount={Math.ceil(specialties.length / numberOfItemsPerPage)}
        onPageChange={handleChangePage}
      />
    </SpecialtiesContainer>
  );
};

export default Specialties;

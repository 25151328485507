import httpClient from 'src/http-client';

const createCertificate = async (contentId: string) => {
  return (await httpClient.post(`/certificates`, { content_id: contentId }))
    .data;
};

const getCertificates = async (params?: any) => {
  return (await httpClient.get(`/certificates`, { params })).data;
};

export { createCertificate, getCertificates };

import React from 'react';
import { FormButton } from '../FormButton/style'
import { PopUpContainer } from './style';

interface PopUpProps {
  keepSubscription: () => void;
  cancelSubscription: () => void;
}

const ConfirmationPopUp: React.FC<PopUpProps> = ({ keepSubscription, cancelSubscription }) => {

  return (
    <PopUpContainer>
      <div className="pop-up">
        <h3>Tem certeza que deseja cancelar a assinatura?</h3>

        <ul>
          <li>Será cancelado imediatamente.</li>
          <li>Seu acesso permanece até o vencimento da sua assinatura atual.</li>
        </ul>

        <div className="buttons">
          <FormButton
            onClick={keepSubscription}
          >
            CONTINUAR MINHA ASSINATURA
          </FormButton>

          <FormButton
            onClick={cancelSubscription}
            redOnWhite
          >
            CANCELAR ASSINATURA
          </FormButton>
        </div>
      </div>
    </PopUpContainer>
  );
};

export default ConfirmationPopUp;

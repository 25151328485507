import React, { useCallback, useEffect, useState } from 'react';
import showErrorMessage from 'src/helpers/showErrorMessage';
import SystemError from 'src/models/error';
import useVideos from '../../hooks/useVideos';
import AddToList from './components/AddToList';
import BlankPlayer from './components/BlankPlayer';
import ContentTabs from './components/ContentTabs';
import PlayerSambavideos, {
  PlayerEventListener,
} from './components/PlayerSambaVideos';
import RateStars from './components/RateStars';
import {
  ActualVideoContentContainer,
  AddToListAndRateContainer,
  BannerImage,
  ContentAuthor,
  ContentTitle,
} from './style';
import {
  startContent as startContentService,
  finishContent as finishContentService,
  updateContentProgress as updateContentProgressService,
} from 'src/services/contents';
import {
  startSpecialty as startSpecialtyService,
  finishSpecialty as finishSpecialtyService,
} from 'src/services/specialties';
import ContentUser from 'src/models/content-user';
import { useParams } from 'react-router-dom';
import { createCertificate as createCertificateService } from 'src/services/certificates';

const ActualVideoContent: React.FC = () => {
  const { specialtyId } = useParams<'specialtyId'>();
  const {
    selectedContent,
    specialty,
    goToNextContent,
    reloadSelectedContent,
    allContents,
    reloadData,
  } = useVideos();
  const [lastWatchedTime, setLastWatchedTime] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);

  const createCertificate = useCallback(async () => {
    try {
      await createCertificateService(selectedContent?.content_id!);
    } catch (error) {
      showErrorMessage(error as SystemError);
    }
  }, [selectedContent?.content_id]);

  const startContent = async () => {
    if (selectedContent && !selectedContent.content_user?.start_at) {
      try {
        if (specialty?.content_id && !specialty?.content_user?.start_at) {
          await startSpecialtyService(specialty?.content_id!);
        }

        await startContentService(selectedContent?.content_id);

        if (selectedContent.content_user) {
          selectedContent.content_user.start_at = new Date().toLocaleString();
        } else {
          selectedContent.content_user = {
            start_at: new Date().toLocaleString(),
          } as ContentUser;
        }

        reloadSelectedContent();
      } catch (error) {
        showErrorMessage(error as SystemError);
      }
    }
  };

  const finishContent = async () => {
    if (
      !!selectedContent?.content_user?.start_at &&
      !selectedContent?.content_user?.finish_at
    ) {
      try {
        if (selectedContent.content_user) {
          selectedContent.content_user.finish_at = new Date().toLocaleString();
        } else {
          selectedContent.content_user = {
            finish_at: new Date().toLocaleString(),
          } as ContentUser;
        }

        await finishContentService(selectedContent?.content_id);

        await createCertificate();

        reloadSelectedContent();
      } catch (error) {
        showErrorMessage(error as SystemError);
      }
    }

    if ((allContents || []).every((c) => !!c.content_user?.finish_at)) {
      if (specialty?.content_id && !specialty?.content_user?.finish_at) {
        if (specialty?.content_user) {
          specialty.content_user.finish_at = new Date().toLocaleString();
        } else {
          specialty.content_user = {
            finish_at: new Date().toLocaleString(),
          } as ContentUser;
        }

        await finishSpecialtyService(specialty?.content_id!);

        await reloadData();
      }
    }
  };

  const hasReachedCompletionTime = (duration: number, currentTime: number) => {
    const totalDurationInSecs = duration;
    const completionRate = 0.9;

    const completionTime = totalDurationInSecs * completionRate;
    return currentTime >= completionTime;
  };

  const checkIsCompleted = async (player: PlayerEventListener) => {
    const { event, eventParam, duration } = player;
    if (event !== 'onProgress' || !eventParam) {
      return false;
    }

    if (hasReachedCompletionTime(duration, eventParam)) {
      await finishContent();
    }
  };

  const updateWatchTime = (player: PlayerEventListener) => {
    const { event, eventParam } = player;

    if (event === 'onProgress') {
      setProgress(eventParam);
    }
    return null;
  };

  const getEventListeners = async (player: PlayerEventListener) => {
    switch (player.event) {
      case 'onProgress':
        updateWatchTime(player);
        await checkIsCompleted(player);
        break;

      case 'onStart':
        await startContent();
        break;

      case 'onFinish':
        await finishContent();
        goToNextContent();
        break;
    }
  };

  const updateContentProgress = async (time: number | null) => {
    if (selectedContent) {
      await updateContentProgressService(selectedContent.content_id, time);
    }
  };

  useEffect(() => {
    if (progress >= lastWatchedTime + 30) {
      setLastWatchedTime(progress);
      updateContentProgress(progress);
      return;
    }
    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress);
      } else {
        setLastWatchedTime(0);
        updateContentProgress(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastWatchedTime, progress, selectedContent?.content_id]);

  return (
    <ActualVideoContentContainer>
      {selectedContent ? (
        <PlayerSambavideos
          contentReference={selectedContent.reference}
          resume={selectedContent.content_user?.content_view}
          getEventListeners={getEventListeners}
        />
      ) : specialty ? (
        <BannerImage
          src={
            specialtyId && specialty?.images?.banner
              ? specialty?.images?.banner
              : ''
          }
        />
      ) : (
        <BlankPlayer />
      )}
      <AddToListAndRateContainer>
        <AddToList />
        <RateStars />
      </AddToListAndRateContainer>
      <ContentTitle>{selectedContent?.name || specialty?.name}</ContentTitle>
      {!!selectedContent && (
        <ContentAuthor>
          <span>por</span>{' '}
          <strong>
            {(selectedContent.authors || []).map((a) => a.name).join(', ')}
          </strong>
        </ContentAuthor>
      )}

      <ContentTabs />
    </ActualVideoContentContainer>
  );
};

export default ActualVideoContent;

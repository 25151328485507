import check from '../../assets/check.svg';
import styled from 'styled-components';

const CardWrapper = styled.div`
  background-color: #e2e2e2;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 36px;
  width: 332px;
  justify-content: center;
  border-radius: 8px;

  & > h2 {
    color: var(--primary-color);
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 24px;
  }

  & > div {
    & > h1 {
      color: var(--secondary-color);
      font-family: 'Open Sans', sans-serif;
      font-size: 32px;
      font-weight: 700;
      padding-bottom: 32px;
    }

    & > h3 {
      color: var(--secondary-color);
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: 400;
      text-decoration: line-through;
    }
  }

  & > p {
    color: var(--secondary-color);
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 48px;
  }

  & > ul {
    list-style-position: inside;
    list-style-image: url(${check});
    color: #25282b;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 44px;
    
    & > li {
      padding-bottom: 12px;
    }
  }
`

export { CardWrapper }
import SwiperCore, { Navigation, Pagination, Mousewheel } from 'swiper';
import { Swiper } from 'swiper/react/swiper-react.js';
import { SwiperSlide } from 'swiper/react/swiper-react.js';
import { useCallback, useEffect, useState } from 'react';
import { getContents } from 'src/services/contents';
import { Container, StylesSwiper } from './style';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import Content from 'src/models/content';
import HighlightVideoSlide from '../HighlightVideoSlide';

SwiperCore.use([Navigation, Pagination, Mousewheel]);

const HighlightedVideoSlider = () => {
  const [contentsHighlight, setContentsHighlight] = useState<Content[]>();

  const getContentsHighlight = useCallback(async () => {
    const { data: contents } = await getContents({
      is_highlight: true,
      relations: ['content_user'],
    });

    setContentsHighlight(contents);
  }, []);

  useEffect(() => {
    getContentsHighlight();
  }, [getContentsHighlight]);

  return (
    <Container>
      <StylesSwiper>
        <Swiper
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          className="mySwiper"
        >
          {contentsHighlight?.map((contentHighlight) => {
            return (
              <SwiperSlide key={contentHighlight.content_id}>
                <HighlightVideoSlide content={contentHighlight} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </StylesSwiper>
    </Container>
  );
};

export default HighlightedVideoSlider;

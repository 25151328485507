import httpClient from 'src/http-client';
import LoginCredentials from 'src/models/login-credentials';
import LoginResponse from 'src/models/login-response';

const login = async (credentials: LoginCredentials): Promise<LoginResponse> => {
  const response = (
    await httpClient.post<LoginResponse>(`/sessions/login`, credentials)
  ).data;

  return response;
};

export { login };

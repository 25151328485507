import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppContextProvider from './components/AppContextProvider';
import AppLayout from './components/AppLayout';
import Routes from './routes/index';
import 'react-toastify/dist/ReactToastify.css';
import PartnerContextProvider from './components/PartnerContextProvider';
import ThemesContextProvider from './components/ThemesContextProvider';
import SearchContextProvider from './components/SearchContextProvider';
import Loading from './components/Loading';
import LoadingContextProvider from './components/LoadingContextProvider';
import WindowSizeContextProvider from './components/WindowSizeContextProvider';
import MobileMenuContextProvider from './components/MobileMenuContextProvider';

const App: React.FC = () => {
  return (
    <Router>
      <AppContextProvider>
        <PartnerContextProvider>
          <ThemesContextProvider>
            <SearchContextProvider>
              <WindowSizeContextProvider>
                <MobileMenuContextProvider>
                  <LoadingContextProvider>
                    <AppLayout>
                      <Routes />
                    </AppLayout>
                    <ToastContainer position="bottom-right" />
                    <Loading />
                  </LoadingContextProvider>
                </MobileMenuContextProvider>
              </WindowSizeContextProvider>
            </SearchContextProvider>
          </ThemesContextProvider>
        </PartnerContextProvider>
      </AppContextProvider>
    </Router>
  );
};

export default App;

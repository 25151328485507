import React, { useCallback, useEffect, useRef, useState } from 'react';
import Theme from 'src/models/theme';
import { ThemeCardContainer, ThemeTitle, ThemeWatch } from './style';
import { ReactComponent as ThemeIcon } from 'src/assets/theme-icon.svg';
import { ReactComponent as GoForwardIcon } from 'src/assets/go-forward-icon.svg';
import usePartner from 'src/hooks/usePartner';
import Specialty from 'src/models/specialty';
import clamp from 'clamp-js';

interface ThemeCardProps {
  theme: Theme;
  specialty: Specialty;
}

const ThemeCard: React.FC<ThemeCardProps> = ({ theme, specialty }) => {
  const [hovered, setHovered] = useState(false);
  const { navigate } = usePartner();
  const themeTitle = useRef<HTMLHeadingElement>(null);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const handleClick = useCallback(() => {
    navigate(
      `/videos/specialty/${specialty.content_id}/theme/${theme.content_id}`
    );
  }, [navigate, specialty.content_id, theme.content_id]);

  useEffect(() => {
    if (themeTitle.current) {
      clamp(themeTitle.current, { clamp: 2 });
    }
  }, [themeTitle]);

  return (
    <ThemeCardContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div className="icon-container">
        <ThemeIcon />
      </div>

      <ThemeTitle ref={themeTitle} hovered={hovered}>
        {theme.name}
      </ThemeTitle>
      {hovered && (
        <ThemeWatch>
          <span>Assistir</span>
          <GoForwardIcon />
        </ThemeWatch>
      )}
    </ThemeCardContainer>
  );
};

export default ThemeCard;

import styled from 'styled-components';

const SecurityContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 932px;
  flex: 1;

  .subtitle {
    margin: 16px 0 48px;
  }

  .input-container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 40px;
    margin-bottom: 56px;

    .input-inner-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
`;

export { SecurityContainer };

import styled from 'styled-components';

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;

const ResultDescription = styled.p`
  .search-text {
    color: var(--secondary-color);
    font-weight: bold;
  }
`;

const ContentsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
`;

const SpecialtiesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-top: 48px;
`;

const NoResultFound = styled.p``;

const SpecialtiesTitle = styled.h2`
  grid-column: 1 / 4;
  color: var(--secondary-color);
`;

const ContentsTitle = styled.h2`
  grid-column: 1 / 4;
  color: var(--secondary-color);
`;

export {
  SearchContainer,
  ContentsContainer,
  SpecialtiesContainer,
  ResultDescription,
  NoResultFound,
  ContentsTitle,
  SpecialtiesTitle,
};

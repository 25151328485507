import React, { useState } from 'react';
import ConfirmationPopUp from '../ConfirmationPopup';
import { FormButton } from '../FormButton/style'
import { SubscriptionContainer } from './style';

const Subscription: React.FC = () => {
  const [canceled, setCanceled] = useState<boolean>(false);
  const [displayPopUp, setDisplayPopUp] = useState<boolean>(false);

  return (
    <>
      <SubscriptionContainer>

        <h1 className="main-title">Assinatura</h1>
        <h4 className="subtitle">Visualize e altere seu plano de assinatura aqui.</h4>
        <h5 className="small-title">Tipo de assinatura</h5>
        <h3 className="section-title">{`Plano mensal ${'Premium'} - R$${'79,99'}/mês`}</h3>
        <h5 className="small-title">Pagamento</h5>
        <h4 className="subtitle">{`Cartão de crédito: •••• •••• •••• ${4584}`}</h4>
        <h5 className="small-title">Renova em</h5>
        <h3 className="date">{'01/09/2021'}</h3>

        {canceled ? (
          <FormButton >
            RENOVAR PLANO
          </FormButton>
        ) : (
          <div className="buttons-container">
            <FormButton >
              ALTERAR PLANO
            </FormButton>

            <FormButton
              onClick={() => setDisplayPopUp(true)}
              redOnWhite
            >
              CANCELAR ASSINATURA
            </FormButton>
          </div>
        )}
      </SubscriptionContainer>
      {displayPopUp &&
        <ConfirmationPopUp
          keepSubscription={() => setDisplayPopUp(false)}
          cancelSubscription={() => {
            setCanceled(true);
            setDisplayPopUp(false);
          }}
        />
      }
    </>
  );
};

export default Subscription;

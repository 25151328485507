import styled from 'styled-components';

const SubscriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 932px;
  flex: 1;

  .main-title {
    margin-bottom: 16px;
  }

  .subtitle {
    margin-bottom: 48px;

    :last-of-type {
      margin-bottom: 32px;
      line-height: 33px;
    }
  }

  .section-title {
    margin-bottom: 32px;
    line-height: 32px;
  }

  .small-title {
    color: var(--secondary-color);
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .date {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 56px;
  }

  .small-title, .date {
    color: var(--secondary-color);
    font-weight: bold;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    gap: 32px;
    flex: 1;
  }

  @media (max-width: 1100px) {
    .buttons-container {
      flex-direction: column;
    }
  }
`;

export { SubscriptionContainer };

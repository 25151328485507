import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PartnerContext from 'src/contexts/PartnerContext';
import Partner from 'src/models/partner';
import { getPartnerByUrlComplement } from 'src/services/partner';
import { createGlobalStyle } from 'styled-components';
import urljoin from 'url-join';
import Button from './Button';

interface GlobalStylesProps {
  primaryColor?: string;
  secondaryColor?: string;
}

const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
  :root{
    --primary-color: ${(props) =>
      props.primaryColor ? props.primaryColor : '#D70926'};
    --secondary-color: ${(props) =>
      props.secondaryColor ? props.secondaryColor : '#1E1246'};
  }
`;

const PartnerContextProvider: React.FC = ({ children }) => {
  const goTo = useNavigate();
  const location = useLocation();
  const [partner, setPartner] = useState<Partner | undefined>();
  const { partnerUrlComplement } = useParams<'partnerUrlComplement'>();

  const getFullPathName = useCallback(
    (url: string) => {
      const urlPrefix = `/${partner?.url_complement || 'manole'}`;
      return urljoin(urlPrefix, url);
    },
    [partner?.url_complement]
  );

  interface PartnerLinkProps {
    to: string;
  }

  const PartnerLink: React.FC<PartnerLinkProps> = ({ children, to }) => {
    return (
      <Button type="button" onClick={() => navigate(to)}>
        {children}
      </Button>
    );
  };

  const navigate = useCallback(
    (url: string) => {
      goTo(getFullPathName(url || '/'));
    },
    [getFullPathName, goTo]
  );

  const getPartner = useCallback(async () => {
    const urlComplement =
      partnerUrlComplement && partnerUrlComplement !== null
        ? partnerUrlComplement
        : 'manole';
    const partner = await getPartnerByUrlComplement(urlComplement);
    setPartner(partner);
    window.localStorage.setItem('manole-partner', urlComplement);
  }, [partnerUrlComplement]);

  useEffect(() => {
    if (
      location.pathname !== '' &&
      location.pathname !== '/' &&
      location.pathname !== '/login'
    ) {
      getPartner();
    }
  }, [getPartner, location.pathname]);

  return (
    <PartnerContext.Provider value={{ partner, navigate, PartnerLink }}>
      <GlobalStyles
        primaryColor={partner?.color?.primary}
        secondaryColor={partner?.color?.secondary}
      />
      {children}
    </PartnerContext.Provider>
  );
};

export default PartnerContextProvider;

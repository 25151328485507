import React, { useCallback, useEffect, useState } from 'react';
import {
  GoToLandingLink,
  LoginButton,
  LoginContainer,
  LoginForm,
  LoginFormButtonGroup,
  LoginFormGroup,
  LoginHeader,
  LoginInput,
  SideAndFormContainer,
  SideContent,
  SideContentText,
} from './style';
import { ReactComponent as GoBack } from 'src/assets/back-arrow.svg';
import { ReactComponent as Logo } from 'src/assets/login-logo.svg';
import LoginCredentials from 'src/models/login-credentials';
import showErrorMessage from 'src/helpers/showErrorMessage';
import SystemError from 'src/models/error';
import useLoading from 'src/hooks/useLoading';
import usePartner from 'src/hooks/usePartner';
import { login as loginService } from 'src/services/login';
import checkEmptyString from 'src/helpers/check-empty-string';
import { getUser } from 'src/services/users';

const Login: React.FC = () => {
  const { setIsLoading } = useLoading();
  const { navigate } = usePartner();
  const [credentials, setCredentials] = useState({} as LoginCredentials);

  const handleChangeCredentials = useCallback(
    (propName: string, newValue: any) => {
      setCredentials({ ...credentials, [propName]: newValue });
    },
    [credentials]
  );

  const validate = useCallback(() => {
    if (checkEmptyString(credentials.email)) {
      throw new Error('Informe seu email!');
    }

    if (checkEmptyString(credentials.password)) {
      throw new Error('Informe sua senha!');
    }
  }, [credentials.email, credentials.password]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      setIsLoading(true);

      try {
        validate();

        const { token, user } = await loginService(credentials);
        window.localStorage.setItem('manole-api-token', token);
        window.localStorage.setItem('manole-user', JSON.stringify(user));
        navigate('/home');
      } catch (error) {
        showErrorMessage(error as SystemError);
      }

      setIsLoading(false);
    },
    [credentials, navigate, setIsLoading, validate]
  );

  const tryToGetUser = useCallback(async () => {
    try {
      await getUser();

      navigate('/home');
    } catch (e) {}
  }, [navigate]);

  useEffect(() => {
    const hasToken = !!window.localStorage.getItem('manole-api-token');
    if (hasToken) {
      tryToGetUser();
    }
  }, [tryToGetUser]);

  return (
    <LoginContainer>
      <LoginHeader className="max-content">
        <Logo />
      </LoginHeader>
      <SideAndFormContainer className="max-content">
        <SideContent>
          <GoToLandingLink to="/">
            <GoBack />
          </GoToLandingLink>
          <SideContentText>Acesse sua conta</SideContentText>
        </SideContent>
        <LoginForm onSubmit={handleSubmit}>
          <LoginFormGroup>
            <label htmlFor="email">E-mail</label>
            <LoginInput
              id="email"
              type="email"
              value={credentials.email}
              onChange={(e) => handleChangeCredentials('email', e.target.value)}
            />
          </LoginFormGroup>
          <LoginFormGroup>
            <label htmlFor="password">Senha</label>
            <LoginInput
              id="password"
              type="password"
              value={credentials.password}
              onChange={(e) =>
                handleChangeCredentials('password', e.target.value)
              }
            />
          </LoginFormGroup>
          <LoginFormButtonGroup>
            <LoginButton>Entrar</LoginButton>
          </LoginFormButtonGroup>
        </LoginForm>
      </SideAndFormContainer>
    </LoginContainer>
  );
};

export default Login;

import React, { useState } from 'react';
import profilePicture from 'src/assets/profile-picture.png';
import { ReactComponent as Camera } from 'src/assets/camera.svg';
import FormInput from 'src/components/FormInput';
import { FormButton } from '../FormButton/style';
import { PersonalDataContainer, NameAndPicture, Form } from './style';

const PersonalData: React.FC = () => {
  const [name, setName] = useState<string>('Maria Júlia');
  const [cpf, setCpf] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [cep, setCep] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [district, setDistrict] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [editing, setEditing] = useState<boolean>(false);

  const formatNumber = (value: string) => {
    if (!value) return '';
    const numbers = value.replace(/[^\d]/g, '');
    const len = numbers.length;
    let formattedValue;

    if (len < 4) {
      return numbers;
    } else if (len > 11) {
      return value.slice(0, 14);
    }

    formattedValue = `${numbers.slice(0, 3)}.${numbers.slice(3, len)}`;

    if (len > 6) {
      formattedValue = `${formattedValue.slice(0, 7)}.${formattedValue.slice(7, len + 1)}`
    }

    if (len > 9) {
      formattedValue = `${formattedValue.slice(0, 11)}-${formattedValue.slice(11, len + 2)}`
    }

    return formattedValue;
  };

  return (
    <PersonalDataContainer>
      <h2 className="main-title">Dados Pessoais</h2>
      <h4 className="subtitle">Confirme e altere seus dados aqui.</h4>
      <h3 className="section-title">Informações pessoais</h3>

      <NameAndPicture>
        <img src={profilePicture} alt="Foto de perfil" />
        <div className="right-side">
          <h2>{name}</h2>
          <button className="change-picture" >
            <Camera />
            <span>Alterar foto de perfil</span>
          </button>
        </div>
      </NameAndPicture>

      <Form onSubmit={(e) => {
        e.preventDefault();
        if (editing) {
          setEditing(false);
        } else {
          setEditing(true);
        }
      }}>
        <div className="line">
          <FormInput onChange={value => setName(value)} value={name} label="Nome completo" disabled={!editing} />
          <FormInput onChange={value => setCpf(formatNumber(value))} value={cpf} label="CPF" disabled={!editing} />
        </div>
        <div className="line">
          <FormInput onChange={value => setEmail(value)} value={email} label="E-mail" disabled={!editing} />
          <FormInput onChange={value => setPhone(value)} value={phone} label="Celular" disabled={!editing} />
        </div>

        <h3 className="section-title address-title">Endereço</h3>

        <div className="line">
          <FormInput onChange={value => setAddress(value)} value={address} label="Endereço" flex={4} disabled={!editing} />
          <FormInput onChange={value => setCep(value)} value={cep} label="CEP" disabled={!editing} />
        </div>
        <div className="line">
          <div className="inner-line">
            <FormInput onChange={value => setNumber(value)} value={number} label="Número" disabled={!editing} />
            <FormInput onChange={value => setDistrict(value)} value={district} label="Bairro" flex={3} disabled={!editing} />
            <FormInput onChange={value => setCity(value)} value={city} label="Cidade" flex={3} disabled={!editing} />
          </div>
          <FormInput onChange={value => setState(value)} value={state} label="Estado" disabled={!editing} />
        </div>
        <FormButton type="submit">{editing ? 'SALVAR ALTERAÇÕES' : 'EDITAR MEUS DADOS'}</FormButton>
      </Form>
    </PersonalDataContainer>
  );
};

export default PersonalData;

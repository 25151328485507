import styled from 'styled-components';

const SubHeaderDivider = styled.div`
  width: 150vw;
  transform: translateX(-33%);
  position: relative;
  border-top: solid 1px rgba(255, 255, 255, 0.4);
  margin: 32px 0;
`;

export { SubHeaderDivider };

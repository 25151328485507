import styled from 'styled-components';
import Button from '../Button';

const HeaderContainer = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 100vw;
  background: #11092b33;
  display: flex;
  align-items: center;
  z-index: 9;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const HeaderLinks = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin: 0 32px;
`;

interface HeaderLinkProps {
  active: boolean;
}

const HeaderLink = styled(Button)<HeaderLinkProps>`
  font-weight: ${({ active }) => (active ? '600' : 'normal')};
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  padding: 16px;
  color: ${({ active }) => (active ? 'var(--primary-color)' : 'var(--white)')};
  border-bottom: ${({ active }) =>
    active ? '2px solid var(--primary-color)' : '2px solid transparent'};
`;

const MobileMenuOpenButton = styled(Button)``;

export {
  HeaderContainer,
  HeaderLinks,
  HeaderContent,
  HeaderLink,
  MobileMenuOpenButton,
};

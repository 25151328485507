import styled from 'styled-components';

const VideosListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  background-color: var(--gray-lighter);
  border-radius: 4px;
`;

const TitleAndAutoPlayToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
`;

const ContentList = styled.div`
  display: flex;
  flex-direction: column;
`;

const VideosTitle = styled.h4``;

export {
  VideosListContainer,
  TitleAndAutoPlayToggleContainer,
  ContentList,
  VideosTitle,
};

import httpClient from 'src/http-client';
import Author from 'src/models/author';
import Content from 'src/models/content';
import Paginated from 'src/models/paginated';

const getLandingContents = async (
  filters?: any
): Promise<Paginated<Content>> => {
  return (await httpClient.get(`/contents/landing`, { params: filters })).data;
};

const getLandingAuthors = async (): Promise<Author[]> => {
  return (await httpClient.get<Author[]>(`/authors/landing`)).data;
};

export { getLandingContents, getLandingAuthors };

const planInfo: {
  id: number;
  title: string;
  price1: string;
  price2: string;
  text: string;
  buttonText: string;
  liText: string[];
  path: string;
}[] = [

  {
    id: 1,
    title: 'Gratuito',
    price1: 'Testar gratuitamente',
    price2: '',
    text: 'Conheça a plataforma antes de assinar',
    buttonText: 'Testar gratuitamente',
    liText: [
      'Veja como funciona a plataforma',
      'Assista em qualquer dispositivo',
    ],
    path: 'sign-up',
  },
  {
    id: 2,
    title: 'Plano Premium',
    price1: 'R$ 49,99 / mês',
    price2: 'R$ 59,99 / mês',
    text: 'Aqui você terá acesso as trilhas de todas as especialidades disponíveis na plataforma.',
    buttonText: 'Quero assinar',
    liText: [
      'Assista em qualquer dispositivo',
      'Cancele quando quiser',
      'Acesso a todo o catálogo',
    ],
    path: 'sign-up',
  },
];

export default planInfo
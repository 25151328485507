import {
  Document,
  Page,
  Image,
  StyleSheet,
  Text,
  View,
  Font,
} from '@react-pdf/renderer';
import React from 'react';
import CertBackground from 'src/assets/cert-background.jpeg';
import Certificate from 'src/models/certificate';
import Content from 'src/models/content';
import User from 'src/models/user';
import RegularFont from 'src/assets/regular-font.ttf';
import BoldFont from 'src/assets/bold-font.ttf';

Font.register({
  family: 'Regular',
  fonts: [
    {
      src: RegularFont,
      fontWeight: 'normal',
    },
    {
      src: BoldFont,
      fontWeight: 'bold',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    width: '100%',
  },

  firstPage: {
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: '100%',
    minHeight: '100%',
    height: '100%',
    width: '100%',
  },

  background: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },

  textContent: {
    position: 'absolute',
    top: '36%',
    left: '5.5%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Regular',
  },

  contentTitleAndEditionNameContainer: {
    marginBottom: '20pt',
    color: '#c6ae87',
    textTransform: 'uppercase',
    maxWidth: '70%',
  },

  contentTitle: {
    textTransform: 'uppercase',
    fontSize: '24pt',
    fontWeight: 'bold',
  },
  editionName: {
    fontSize: '12pt',
  },

  userNameAndContentDescriptionContainer: {
    maxWidth: '70%',
  },

  userName: {
    fontSize: '24pt',
    fontWeight: 'bold',
  },
  contentName: { fontWeight: 'bold' },
  contentDescription: {
    fontSize: '14pt',
  },

  dateAndLocation: {
    position: 'absolute',
    top: '77%',
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

interface CertificateTemplateProps {
  certificate: Certificate;
  content: Content;
  user: User;
}

const CertificateTemplate: React.FC<CertificateTemplateProps> = ({
  certificate,
  content,
  user,
}) => {
  const contentCreationDate = new Date(content.created_at);
  const contentCreationYear = contentCreationDate.getFullYear();

  const secondsToHours = (seconds: number) => {
    return (seconds || 0) / 60 / 60;
  };

  const hoursOfCourse = secondsToHours(certificate.content_duration || 0) || 0;

  const contentFinishDate = certificate.content_finish_at
    ? new Intl.DateTimeFormat('pt-BR', {
        dateStyle: 'long',
      }).format(new Date(certificate.content_finish_at))
    : new Intl.DateTimeFormat('pt-BR', {
        dateStyle: 'long',
      }).format(new Date());

  return (
    <Document title={`Certificado-${certificate.content_name}`}>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.firstPage}>
          <Image
            style={styles.background}
            src={CertBackground}
            //@ts-ignore
            alt="Imagem de Fundo"
          />
          <View style={styles.textContent}>
            <View style={styles.contentTitleAndEditionNameContainer}>
              <Text style={styles.contentTitle}>
                {certificate.content_name}
              </Text>
              <Text style={styles.editionName}>
                EDIÇÃO {contentCreationYear}
              </Text>
            </View>

            <View style={styles.userNameAndContentDescriptionContainer}>
              <Text style={styles.userName}>{user.name}</Text>
              <Text style={styles.contentDescription}>
                concluiu o curso{' '}
                <Text style={styles.contentName}>
                  {certificate.content_name}
                </Text>
                , realizado na modalidade on-line, com carga horária de{' '}
                {hoursOfCourse.toFixed(2)} hora
                {hoursOfCourse > 1 ? 's' : ''}.
              </Text>
            </View>
          </View>

          <Text style={styles.dateAndLocation}>
            São Paulo, {contentFinishDate}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default CertificateTemplate;

import styled, { css } from 'styled-components';

const MenuContainer = styled.div`
  border-right: 1px solid #cfd2d4;
  width: 288px;
  min-width: 220px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: start;
  padding-left: 48px;

  @media (max-width: 1000px) {
    flex-direction: row;
    padding: 0 16px;
    width: 100%;
    margin-bottom: 16px;
  }
`;

interface MenuButtonProps {
  isActive: boolean;
}

const MenuButton = styled.button<MenuButtonProps>`
  text-align: left;
  font-family: Open Sans;
  font-size: 20px;
  line-height: 32px;
  color: var(--secondary-color);
  opacity: 0.7;
  background: none;
  border: none;
  outline: none;

  ${(props) =>
    props.isActive &&
    css`
      color: var(--primary-color);
      font-weight: 700;
    `}
`;

export { MenuContainer, MenuButton };

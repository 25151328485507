import styled from 'styled-components';

const SubHeaderTitleContainer = styled.div`
  color: var(--white);
  font-family: 'Open Sans', sans-serif;
  font-size: 2.6em;
  font-weight: bold;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 16px 0 32px 0;
`;

export { SubHeaderTitleContainer };

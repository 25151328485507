import httpClient from '../http-client';
import Partner from '../models/partner';

const getPartnerByUrlComplement = async (urlComplement: string) => {
  const partners = (await httpClient.get<Partner[]>(`/partners`)).data;

  const partner = (partners || []).find(
    (p) => p.url_complement === urlComplement
  );

  return partner;
};

export { getPartnerByUrlComplement };

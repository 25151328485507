import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AuthContext from '../contexts/AuthContext';
import User from '../models/user';
import { getUser as getUserService } from 'src/services/users';
import { useLocation } from 'react-router-dom';

const AuthContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | undefined>();
  const location = useLocation();

  const getUser = useCallback(async () => {
    if (
      !(
        location.pathname.includes('accessDenied') ||
        location.pathname.includes('somethingWrong')
      )
    ) {
      const user = await getUserService();
      setUser(user);

      window.localStorage.setItem('manole-user', JSON.stringify(user));
    }
  }, [location.pathname]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const isAuthenticated = useMemo(() => {
    return !!user;
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, setUser, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

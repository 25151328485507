import React, { useCallback, useContext, useState } from 'react';
import SubHeader from 'src/components/SubHeader';
import SubHeaderTitle from 'src/components/SubHeader/SubHeaderTitle';
import Breadcrumb from 'src/components/Breadcrumb';
import usePartner from 'src/hooks/usePartner';
import PartnerContext from 'src/contexts/PartnerContext';
import Menu from './components/Menu';
import PersonalData from './components/PersonalData';
import profileBanner from 'src/assets/my-profile.png';
import { MyProfileContainer, Content } from './style';
import Security from './components/Security';
import Subscription from './components/Subscription';
import { SubHeaderDivider } from 'src/components/SubHeaderDivider';
import GoBack from 'src/components/GoBack';
import Certificates from './components/Certificates';

export enum ProfileMenus {
  personalData = 'personalData',
  security = 'security',
  signature = 'signature',
  certificate = 'certificate',
}

const MyProfile: React.FC = () => {
  const [page, setPage] = useState<ProfileMenus>(ProfileMenus.personalData);
  const { partner } = usePartner();
  const { PartnerLink } = useContext(PartnerContext);

  const renderContent = useCallback(() => {
    switch (page) {
      case ProfileMenus.personalData:
        return <PersonalData />;

      case ProfileMenus.security:
        return <Security />;

      case ProfileMenus.signature:
        return <Subscription />;

      case ProfileMenus.certificate:
        return <Certificates />;
    }
  }, [page]);

  return (
    <>
      <SubHeader background={partner?.banner?.mylist || profileBanner}>
        <Breadcrumb
          crumbs={[
            <PartnerLink className="home">Início</PartnerLink>,
            <PartnerLink>Meu perfil</PartnerLink>,
          ]}
        />

        <SubHeaderDivider />

        <GoBack />

        <SubHeaderTitle title={'Meu perfil'} />
      </SubHeader>
      <MyProfileContainer className="max-content">
        <Menu
          page={page}
          onClick={(page) => setPage(page)}
          paidAccount={true}
        />
        <Content>{renderContent()}</Content>
      </MyProfileContainer>
    </>
  );
};

export default MyProfile;

import React, { useCallback, useEffect, useState } from 'react';
import Certificate from 'src/models/certificate';
import {
  CertificatesContainer,
  CertificatesList,
  CertificatesTitle,
} from './style';
import { getCertificates as getCertificatesService } from 'src/services/certificates';
import CertificateCard from './components/CertificateCard';
import useLoading from 'src/hooks/useLoading';

const Certificates: React.FC = () => {
  const [certificates, setCertificates] = useState([] as Certificate[]);
  const { setIsLoading } = useLoading();

  const getCertificates = useCallback(async () => {
    setIsLoading(true);

    const certificates = await getCertificatesService({
      relations: ['content'],
    });
    setCertificates(certificates);

    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    getCertificates();
  }, [getCertificates]);

  return (
    <CertificatesContainer>
      <CertificatesTitle>Certificados</CertificatesTitle>

      <CertificatesList>
        {!!certificates?.length &&
          certificates.map((cert) => (
            <CertificateCard key={cert.certificate_id} certificate={cert} />
          ))}
      </CertificatesList>
    </CertificatesContainer>
  );
};

export default Certificates;

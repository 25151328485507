import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BiUserCircle } from 'react-icons/bi';
import { FiLogOut } from 'react-icons/fi';
import { IoIosArrowDown } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import usePartner from 'src/hooks/usePartner';
import User from 'src/models/user';
import useApp from '../../../hooks/useApp';
import { DropdownButton, DropdownContainer, DropdownNav } from './style';

const DropdownHeader: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { PartnerLink } = usePartner();
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const { showUserContextMenu, setShowUserContextMenu } = useApp();
  const [user, setUser] = useState<undefined | User>();

  const handleLogout = useCallback(() => {
    window.localStorage.removeItem('manole-api-token');
    window.localStorage.removeItem('manole-user');
    navigate('/');
  }, [navigate]);

  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    setShowUserContextMenu(!showUserContextMenu);
  };

  const getUser = useCallback(() => {
    const user = window.localStorage.getItem('manole-user')
      ? JSON.parse(window.localStorage.getItem('manole-user') || '{}')
      : undefined;
    setUser(user);
  }, []);

  useEffect(() => {
    getUser();
  }, [getUser, location]);

  return (
    <DropdownContainer>
      <DropdownButton onClick={onClick}>
        <span>
          <BiUserCircle size={30} />
          <span>{user?.name || 'Desconhecido'}</span>
          <IoIosArrowDown />
        </span>
      </DropdownButton>

      <DropdownNav ref={dropdownRef} isActive={showUserContextMenu}>
        <ul>
          <li>
            <PartnerLink type="button" to="/my-progress">
              Meu progresso
            </PartnerLink>
          </li>
          <li>
            <PartnerLink type="button" to="/my-list">
              Minha Lista
            </PartnerLink>
          </li>
          <li>
            <PartnerLink type="button" to="/my-profile">
              Meu perfil
            </PartnerLink>
          </li>
          <li>
            <Button
              type="button"
              className="logout-button"
              onClick={handleLogout}
            >
              <span>
                <FiLogOut />
                Sair
              </span>
            </Button>
          </li>
        </ul>
      </DropdownNav>
    </DropdownContainer>
  );
};

export default DropdownHeader;

import styled from 'styled-components';

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 8;

  label {
    cursor: pointer;
  }
`;

const SelectedOption = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  & .label {
    font-size: 0.9em;
  }

  & .selected-option {
    font-size: 1em;
    color: var(--secondary-color);
    font-weight: 500;
  }
`;

const SelectOptions = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  max-height: 160px;
  width: 100%;
  transition: all 0.3s;
  list-style: none;
  border-radius: 4px;
  box-shadow: 0px 0px 5px var(--gray-darkest);
  z-index: 9;
  background-color: var(--white);

  @keyframes up-to-down-select {
    from {
      transform: translateY(10px);
    }
    to {
      transform: translateY(24px);
    }
  }

  transform: translateY(24px);
  animation: up-to-down-select 0.3s;
`;

const SelectOptionContainer = styled.li`
  padding: 8px;
  text-align: right;
  color: var(--secondary-color);
  font-weight: 500;

  :hover {
    transition: all 0.3s;
    text-decoration: underline;
    font-weight: bold;
  }
`;

export {
  SelectContainer,
  SelectedOption,
  SelectOptions,
  SelectOptionContainer,
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import usePartner from 'src/hooks/usePartner';
import Specialty from 'src/models/specialty';
import {
  ContinueWatching,
  ReWatch,
  SpecialtyCardContainer,
  SpecialtyContentDescription,
  SpecialtyThumbnail,
  SpecialtyTitle,
} from './style';
import { getSpecialty as getSpecialtyService } from 'src/services/specialties';
import { ReactComponent as ProgressPlayIcon } from 'src/assets/progress-play-icon.svg';
import { ReactComponent as ReWatchIcon } from 'src/assets/re-watch-icon.svg';
import { ProgressContainer } from '../VideoCard/style';

interface SpecialtyCardProps {
  specialty: Specialty;
  inProgress?: boolean;
  isFinished?: boolean;
  shouldGetFullSpecialty?: boolean;
}

const SpecialtyCard: React.FC<SpecialtyCardProps> = ({
  specialty: initialSpecialty,
  inProgress,
  isFinished,
  shouldGetFullSpecialty = true,
}) => {
  const [hovered, setHovered] = useState(false);
  const { navigate } = usePartner();
  const [specialty, setSpecialty] = useState({} as Specialty);

  const getSpecialty = useCallback(async () => {
    if (shouldGetFullSpecialty) {
      const specialty = await getSpecialtyService(initialSpecialty.content_id);
      setSpecialty(specialty);
    } else {
      setSpecialty(initialSpecialty);
    }
  }, [initialSpecialty, shouldGetFullSpecialty]);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const handleClick = useCallback(() => {
    navigate(`/specialty/${specialty.content_id}`);
  }, [navigate, specialty.content_id]);

  const themes = useMemo(() => {
    return (specialty || {}).children || [];
  }, [specialty]);

  const videos = useMemo(() => {
    return (themes || []).map((t) => t.children || []).flat();
  }, [themes]);

  useEffect(() => {
    getSpecialty();
  }, [getSpecialty]);

  const contents = useMemo(() => {
    return themes.map((t) => t.children || []).flat() || [];
  }, [themes]);

  const numberOfFinishedContents = useMemo(() => {
    return ((contents || []).filter((c) => !!c?.content_user?.finish_at) || [])
      .length;
  }, [contents]);

  const numberOfContents = useMemo(() => {
    return contents.length;
  }, [contents.length]);

  return (
    <SpecialtyCardContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <SpecialtyThumbnail
        hovered={hovered}
        src={specialty?.images?.thumbnail}
      />
      <SpecialtyTitle hovered={hovered}>{specialty?.name}</SpecialtyTitle>
      {hovered &&
        (inProgress || isFinished ? (
          inProgress ? (
            <ContinueWatching>
              <span className="icon">
                <ProgressPlayIcon />
              </span>
              <span className="text">Continuar playlist</span>{' '}
              <span className="percent">
                {numberOfFinishedContents} de {numberOfContents} vídeo
                {numberOfContents > 1 ? 's' : ''}
              </span>
            </ContinueWatching>
          ) : isFinished ? (
            <ReWatch>
              <span className="icon">
                <ReWatchIcon />
              </span>
              <span className="text">Reassistir</span>
              <span className="percent">Concluído</span>
            </ReWatch>
          ) : (
            <></>
          )
        ) : (
          <SpecialtyContentDescription>
            {themes.length} Tema{themes.length > 1 ? 's' : ''}{' '}
            <span className="divider">•</span> {videos.length} Vídeo
            {videos.length ? 's' : ''}
          </SpecialtyContentDescription>
        ))}

      {inProgress && (
        <ProgressContainer progress={specialty.progress || 0}>
          <span className="progress"></span>
        </ProgressContainer>
      )}

      {isFinished && (
        <ProgressContainer progress={100}>
          <span className="progress"></span>
        </ProgressContainer>
      )}
    </SpecialtyCardContainer>
  );
};

export default SpecialtyCard;

import styled from 'styled-components';

interface FormButtonProps {
  redOnWhite?: boolean;
}

const FormButton = styled.button<FormButtonProps>`
  width: 324px;
  height: 44px;
  background: ${props => props.redOnWhite ? 'none' : 'var(--primary-color)'};
  border: ${props => props.redOnWhite ? '1px solid var(--primary-color)' : 'none'};
  color: ${props => props.redOnWhite ? 'var(--primary-color)' : 'white'};
  border-radius: 6px;
  font-family: Barlow;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
`;

export { FormButton };

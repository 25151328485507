import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    filter: brightness(60%);
    width: 100%;
    height: 100%;
  }
`;

const ContainerContentBanner = styled.div`
  z-index: 9;
`;

const ContentBanner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  h1 {
    font-size: 56px;
    font-weight: bold;
    line-height: 72px;
    text-align: left;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: var(--white);
  }

  p {
    color: var(--white);
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  div {
    display: flex;
    gap: 40px;

    svg {
      font-size: 16px;
      height: 20px;
    }
  }

  @media (max-width: 1200px) {
    h1 {
      font-size: 2.4em;
    }

    & > div {
      flex-wrap: wrap;
    }
  }
`;

const ContainerTheme = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;

  span {
    min-width: 80px;
    max-width: 120px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.75);
    color: var(--primary-color);
    font-size: 18px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export { Container, ContainerContentBanner, ContentBanner, ContainerTheme };

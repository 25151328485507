import httpClient from 'src/http-client';
import Content from 'src/models/content';
import Author from 'src/models/author';
import Paginated from 'src/models/paginated';

const getContents = async (filters?: any): Promise<Paginated<Content>> => {
  return (
    await httpClient.get<Paginated<Content>>(`/contents`, {
      params: {
        ...filters,
        flag: ['content'],
        relations: [...['segmentations'], ...((filters || {}).relations || [])],
      },
    })
  ).data;
};

const getContent = async (contentId: string) => {
  return (
    await httpClient.get<Content>(`/contents/${contentId}`, {
      params: { relations: ['authors', 'content_user', 'extra_materials'] },
    })
  ).data;
};

const createContent = async (newContent: Partial<Content>) => {
  return (
    await httpClient.post(`/contents`, { ...newContent, flag: 'content' })
  ).data;
};

const updateContent = async (
  contentId: string,
  newContent: Partial<Content>
) => {
  delete newContent.content_id;
  delete newContent.created_at;
  delete newContent.updated_at;
  delete newContent.segmentation_items;
  if (newContent.authors?.length) {
    newContent.authors = (newContent.authors || []).map((a) => ({
      author_id: a.author_id,
    })) as Author[];
  }

  return (
    await httpClient.put(`/contents/${contentId}`, {
      ...newContent,
      flag: 'content',
    })
  ).data;
};

const deleteContent = async (contentId: string) => {
  await httpClient.delete(`/contents/${contentId}`);
};

const toggleContentActive = async (
  contentId: string,
  shouldActive: boolean
) => {
  return (
    await httpClient.patch(`/contents/${contentId}/active`, {
      is_active: shouldActive,
    })
  ).data;
};

const toggleContentHighlight = async (contentId: string) => {
  const content = await getContent(contentId);

  content.is_highlight = !content.is_highlight;

  return updateContent(contentId, content);
};

const rateContent = async (contentId: string, newRating: number) => {
  return (
    await httpClient.patch(`/content-users/${contentId}/review`, {
      review_stars: newRating,
      review_description: '',
    })
  ).data;
};

const likeContent = async (contentId: string, like: boolean) => {
  return (await httpClient.patch(`/content-users/${contentId}/like`, { like }))
    .data;
};

const addToListContent = async (contentId: string, addToList: boolean) => {
  return (
    await httpClient.patch(`/content-users/${contentId}/bookmark`, {
      book_mark: addToList,
    })
  ).data;
};

const startContent = async (contentId: string) => {
  return await (
    await httpClient.post(`/content-users/${contentId}/start`)
  ).data;
};

const finishContent = async (contentId: string) => {
  return await (
    await httpClient.post(`/content-users/${contentId}/finish`)
  ).data;
};

const updateContentProgress = async (
  contentId: string,
  content_view: number | null
) => {
  return (
    await httpClient.patch(`/content-users/${contentId}/content-view`, {
      content_view,
    })
  ).data;
};

export {
  getContents,
  getContent,
  createContent,
  updateContent,
  deleteContent,
  toggleContentActive,
  toggleContentHighlight,
  rateContent,
  likeContent,
  addToListContent,
  startContent,
  finishContent,
  updateContentProgress,
};

import { createContext, Dispatch, SetStateAction } from 'react';
import User from '../models/user';

interface IAuthContext {
  isAuthenticated: boolean;
  user: User | undefined;
  setUser: Dispatch<SetStateAction<User | undefined>>;
}

const AuthContext = createContext({} as IAuthContext);

export default AuthContext;

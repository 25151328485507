import SwiperCore, { Navigation, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import { NewContentContainer, StylesSwiper, Title } from './style';
import useWindowSize from 'src/hooks/useWindowSize';
import { useMemo } from 'react';

SwiperCore.use([Navigation, Mousewheel]);

interface DefaultSliderProps {
  title: string;
  items: JSX.Element[];
}

const DefaultSlider: React.FC<DefaultSliderProps> = ({ title, items }) => {
  const { windowWidth } = useWindowSize();

  const slidesPerView = useMemo(() => {
    if (windowWidth > 1200) {
      return 3.6;
    } else if (windowWidth > 1000) {
      return 3.2;
    } else if (windowWidth > 768) {
      return 2.4;
    } else if (windowWidth > 540) {
      return 1.6;
    } else {
      return 1.2;
    }
  }, [windowWidth]);

  return (
    <>
      <NewContentContainer className="max-content">
        <Title>{title}</Title>
      </NewContentContainer>
      <StylesSwiper>
        <Swiper
          slidesPerView={slidesPerView}
          spaceBetween={5}
          slidesPerGroup={3}
          cssMode={true}
          navigation={true}
          mousewheel={true}
          className="mySwiper"
        >
          {items?.map((item) => (
            <SwiperSlide key={item.key}>{item}</SwiperSlide>
          ))}
        </Swiper>
      </StylesSwiper>
    </>
  );
};

export default DefaultSlider;

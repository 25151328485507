import styled from 'styled-components';

const PopUpContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(30, 18, 70, 0.8);
  z-index: 10;

  .pop-up {
    width: 600px;
    height: 526px;
    background: #F2F2F2;
    border-radius: 8px;
    padding: 48px;

    h3 {
      font-weight: bold;
      font-size: 34px;
      line-height: 48px;
    }

    ul {
      display: flex;
      flex-direction: column;
      padding-left: 28px;
      gap: 24px;
      margin: 48px 0 78px;
      li {
        font-size: 20px;
        line-height: 24px;
        }
      }
    }

    h3, li {
      color: var(--secondary-color);
    }

    button {
      width: 100%;
      :first-of-type {
        margin-bottom: 24px;
      }
    }
  }
`;

export { PopUpContainer };

import { Routes as Switch, Route } from 'react-router-dom';
import Specialty from 'src/pages/Specialty';
import Specialties from 'src/pages/Specialties';
import Videos from 'src/pages/Videos';
import AuthContextProvider from '../components/AuthContextProvider';
import Login from '../pages/Login';
import Home from '../pages/Home';
import LandingPage from '../pages/LandingPage';
import Search from 'src/pages/Search';
import MyProfile from 'src/pages/MyProfile';
import MyList from 'src/pages/MyList';
import ErrorPage from 'src/pages/Error';
import MyProgress from 'src/pages/MyProgress';
import HomeContextProvider from 'src/components/HomeContextProvider';
import LandingContextProvider from 'src/components/LandingContextProvider';

const ProtectedRoute: React.FC = ({ children }) => {
  return <AuthContextProvider>{children}</AuthContextProvider>;
};

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/"
        element={
          <LandingContextProvider>
            <LandingPage />
          </LandingContextProvider>
        }
      />

      <Route
        path="/login"
        element={
          <Login />
        }
      />

      <Route
        path="/:partnerUrlComplement/home"
        element={
          <ProtectedRoute>
            <HomeContextProvider>
              <Home />
            </HomeContextProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path="/:partnerUrlComplement/my-profile"
        element={
          <ProtectedRoute>
            <MyProfile />
          </ProtectedRoute>
        }
      />

      <Route
        path="/:partnerUrlComplement/search"
        element={
          <ProtectedRoute>
            <Search />
          </ProtectedRoute>
        }
      />

      <Route
        path="/:partnerUrlComplement/specialties"
        element={
          <ProtectedRoute>
            <Specialties />
          </ProtectedRoute>
        }
      ></Route>

      <Route
        path="/:partnerUrlComplement/specialty/:specialtyId"
        element={
          <ProtectedRoute>
            <Specialty />
          </ProtectedRoute>
        }
      />

      <Route
        path="/:partnerUrlComplement/my-list"
        element={
          <ProtectedRoute>
            <MyList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:partnerUrlComplement/my-list/:showingContent"
        element={
          <ProtectedRoute>
            <MyList />
          </ProtectedRoute>
        }
      />

      <Route
        path="/:partnerUrlComplement/my-progress/"
        element={
          <ProtectedRoute>
            <MyProgress />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:partnerUrlComplement/my-progress/:showingContent"
        element={
          <ProtectedRoute>
            <MyProgress />
          </ProtectedRoute>
        }
      />

      <Route
        path="/:partnerUrlComplement/videos/specialty/:specialtyId"
        element={
          <ProtectedRoute>
            <Videos />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:partnerUrlComplement/videos/specialty/:specialtyId/theme/:themeId/"
        element={
          <ProtectedRoute>
            <Videos />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:partnerUrlComplement/videos/specialty/:specialtyId/theme/:themeId/content/:contentId"
        element={
          <ProtectedRoute>
            <Videos />
          </ProtectedRoute>
        }
      />
      <Route
        path=":partnerUrlComplement/videos/specialty/:specialtyId/content/:contentId"
        element={
          <ProtectedRoute>
            <Videos />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:partnerUrlComplement/videos/content/:contentId"
        element={
          <ProtectedRoute>
            <Videos />
          </ProtectedRoute>
        }
      />

      <Route
        path="/:partnerUrlComplement/accessDenied"
        element={
          <ProtectedRoute>
            <ErrorPage accessDenied />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:partnerUrlComplement/somethingWrong"
        element={
          <ProtectedRoute>
            <ErrorPage somethingWrong />
          </ProtectedRoute>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <ErrorPage notFound />
          </ProtectedRoute>
        }
      />
    </Switch>
  );
};

export default Routes;

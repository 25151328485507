import styled from 'styled-components';

const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  &,
  button {
    font-size: 0.9rem;
    font-weight: normal;
    padding: 0;
    color: var(--white);
  }

  button {
    font-weight: 500;
  }
`;

const CrumbContainer = styled.span`
  display: flex;
  align-items: center;

  :not(:last-child) {
    ::after {
      content: '>';
      padding-left: 16px;
    }
  }
`;

export { BreadcrumbContainer, CrumbContainer };

import React from 'react';
import { useLocation } from 'react-router-dom';
import useApp from 'src/hooks/useApp';
import Footer from '../Footer';
import Header from '../Header';
import { AppLayoutContainer, ContentContainer } from './style';

const AppLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const { setShowUserContextMenu } = useApp();

  const hideContextUseMenu = () => {
    setShowUserContextMenu(false);
  };

  return (
    <AppLayoutContainer onClick={hideContextUseMenu}>
      {location.pathname !== '/login' && <Header />}
      <ContentContainer>{children}</ContentContainer>
      <Footer />
    </AppLayoutContainer>
  );
};

export default AppLayout;

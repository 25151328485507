import styled from 'styled-components';

const MyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  .pagination {
    grid-column: 1 / 4;

    @media (max-width: 1000px) {
      grid-column: 1 / 3;
    }

    @media (max-width: 700px) {
      grid-column: 1 / 2;
    }
  }
`;

const ContentsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const SpecialtiesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const TitleAndSpecialtyContainer = styled.div`
  grid-column: 1 / 4;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    grid-column: 1 / 3;
  }

  @media (max-width: 700px) {
    grid-column: 1 / 2;
  }
`;

const ContentsTitle = styled.h3`
  color: var(--secondary-color);
`;

const SpcecialtiesTitle = styled.h3`
  color: var(--secondary-color);
`;

const ContentsShowMoreButtonContainer = styled.div`
  grid-column: 1 / 4;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    &,
    svg,
    path,
    circle {
      color: var(--primary-color);
      font-weight: bold;
    }
  }

  @media (max-width: 1000px) {
    grid-column: 1 / 3;
  }

  @media (max-width: 700px) {
    grid-column: 1 / 2;
  }
`;

const SpecialtiesShowMoreButtonContainer = styled.div`
  grid-column: 1 / 4;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    &,
    svg,
    path,
    circle {
      color: var(--primary-color);
      font-weight: bold;
    }
  }

  @media (max-width: 1000px) {
    grid-column: 1 / 3;
  }

  @media (max-width: 700px) {
    grid-column: 1 / 2;
  }
`;

const ContentesAndSpecialtiesDivider = styled.div`
  height: 1px;
  margin: 48px auto;
  background-color: var(--gray-lighter);
`;

export {
  MyListContainer,
  ContentsContainer,
  SpecialtiesContainer,
  ContentsTitle,
  SpcecialtiesTitle,
  ContentsShowMoreButtonContainer,
  SpecialtiesShowMoreButtonContainer,
  TitleAndSpecialtyContainer,
  ContentesAndSpecialtiesDivider,
};

import React, { useMemo } from 'react';
import SubHeader from 'src/components/SubHeader';
import ActualVideoContent from './components/ActualVideoContent';
import VideosContextProvider from './components/VideosContextProvider';
import VideosList from './components/VideosList';
import useVideos from './hooks/useVideos';
import { PlayerContainer, VideosContainer, VideosTitle } from './style';
import specialtyDefaultImage from 'src/assets/specialty-default-image.png';
import LatestVideos from './components/LatestVideos';
import Breadcrumb from 'src/components/Breadcrumb';
import usePartner from 'src/hooks/usePartner';
import { SubHeaderDivider } from 'src/components/SubHeaderDivider';
import GoBack from 'src/components/GoBack';
import { useParams } from 'react-router-dom';

const VideosWrapper: React.FC = () => {
  const { specialtyId, themeId } = useParams<'specialtyId' | 'themeId'>();
  const { specialty, theme, allContents, selectedContent } = useVideos();
  const { PartnerLink } = usePartner();

  const crumbs = useMemo(() => {
    const allCrumbs = [<PartnerLink to="/home">Inicio</PartnerLink>] as
      | (JSX.Element | string)[];

    if (specialtyId && specialty) {
      allCrumbs.push(
        <PartnerLink to="/specialties">Especialidades</PartnerLink>,
        <PartnerLink to={`/videos/specialty/${specialty.content_id}`}>
          {specialty.name}
        </PartnerLink>
      );
      if (themeId && theme) {
        allCrumbs.push(
          <PartnerLink
            to={`/specialty/${specialty.content_id}/theme/${theme.content_id}`}
          >
            {theme.name}
          </PartnerLink>
        );
      } else if (selectedContent) {
        allCrumbs.push('Todos os Vídeos');
      }
    } else {
      allCrumbs.push(
        'Vídeo',
        <PartnerLink to={`/videos/content/${selectedContent?.content_id}`}>
          {selectedContent?.name}
        </PartnerLink>
      );
    }

    return allCrumbs;
  }, [PartnerLink, selectedContent, specialty, specialtyId, theme, themeId]);

  const pageTitle = useMemo(() => {
    if (specialty) {
      let title = `${specialty.name}`;
      if (theme) {
        title += ` • ${theme.name}`;
      } else {
        title += ` • Todos os vídeos`;
      }

      return title;
    } else if (selectedContent) {
      return `Vídeo • ${selectedContent.name}`;
    }

    return '';
  }, [selectedContent, specialty, theme]);

  return (
    <>
      <SubHeader
        background={
          specialtyId && specialty?.images.banner
            ? specialty?.images.banner
            : specialtyDefaultImage
        }
      >
        <Breadcrumb crumbs={crumbs} />
        <SubHeaderDivider />
        <GoBack />
        <VideosTitle>{pageTitle}</VideosTitle>
      </SubHeader>
      <PlayerContainer className="max-content">
        <ActualVideoContent />
        {!!allContents.length && <VideosList />}
      </PlayerContainer>
      <LatestVideos />
    </>
  );
};

const Videos: React.FC = () => {
  return (
    <VideosContextProvider>
      <VideosContainer>
        <VideosWrapper />
      </VideosContainer>
    </VideosContextProvider>
  );
};

export default Videos;

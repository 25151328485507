import clamp from 'clamp-js';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AiOutlineCheck, AiOutlineDownload } from 'react-icons/ai';
import Button from 'src/components/Button';
import secondsToTimeText from 'src/helpers/seconds-to-time-text';
import showErrorMessage from 'src/helpers/showErrorMessage';
import Certificate from 'src/models/certificate';
import Content from 'src/models/content';
import SystemError from 'src/models/error';
import useVideos from 'src/pages/Videos/hooks/useVideos';
import CertificateTemplate from 'src/pdf/templates/CertificateTemplate';
import {
  getCertificates as getCertificatesService,
  createCertificate as createCertificateService,
} from 'src/services/certificates';
import styled, { css } from 'styled-components';
import download from 'downloadjs';
import { pdf } from '@react-pdf/renderer';
import useAuth from 'src/hooks/useAuth';
import { getContent as getContentService } from 'src/services/contents';
import useLoading from 'src/hooks/useLoading';

interface VideoPlayerCardContainerProps {
  selected: boolean;
}

const VideoPlayerCardContainer = styled.div<VideoPlayerCardContainerProps>`
  padding: 16px;
  display: grid;
  grid-template-columns: 120px calc(100% - 120px);
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s;

  :hover {
    background: var(--hover);
  }

  ${(props) =>
    props.selected &&
    css`
      background: var(--hover);
    `}
`;

const VideoTitleAndWatchedContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const VideoWatchedContainer = styled.div`
  width: 60px;

  & .watched {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 0.65em;
  }
`;

const DownloadCertButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  font-size: 0.6em;
  background: var(--primary-color);
  color: var(--white);
  border-radius: 4px;
  padding: 4px;
`;

interface VideoWatchedProps {
  video: Content;
}

const VideoWatched: React.FC<VideoWatchedProps> = ({ video }) => {
  const [hovered, setHovered] = useState(false);
  const { user } = useAuth();
  const { setIsLoading } = useLoading();

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const videoWatched = useMemo(() => {
    return !!video.content_user?.finish_at;
  }, [video.content_user?.finish_at]);

  const createCertificate = useCallback(async () => {
    return await createCertificateService(video.content_id);
  }, [video.content_id]);

  const handleDownloadCert = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation();

      setIsLoading(true);

      try {
        if (!videoWatched) {
          throw new Error(
            'Não é possível emitir o certificado de conteúdos ainda não assistidos!'
          );
        }

        const certs = await getCertificatesService({
          content_id: video.content_id,
        });

        let certData: Certificate;
        if ((certs.length || 0) > 0) {
          certData = certs[0];
        } else {
          certData = await createCertificate();
        }

        const content = await getContentService(certData.content_id);

        const template = (
          <CertificateTemplate
            certificate={certData}
            content={content}
            user={user!}
          />
        );

        const blob = await pdf(template).toBlob();

        download(blob, `Certificado-${content.name}.pdf`);
      } catch (error) {
        showErrorMessage(error as SystemError);
      }

      setIsLoading(false);
    },
    [createCertificate, setIsLoading, user, video.content_id, videoWatched]
  );

  return (
    <VideoWatchedContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {videoWatched && (
        <>
          {!hovered && (
            <span className="watched">
              <AiOutlineCheck /> <span>Assistido</span>
            </span>
          )}
          {hovered && (
            <DownloadCertButton type="button" onClick={handleDownloadCert}>
              <AiOutlineDownload /> <span>Baixar Certificado</span>
            </DownloadCertButton>
          )}
        </>
      )}
    </VideoWatchedContainer>
  );
};

interface VideoThumbProps {
  background: string;
}

const VideoThumb = styled.div<VideoThumbProps>`
  position: relative;
  width: 120px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;

  span.duration {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: var(--white);
    font-size: 0.75rem;
    padding: 1px;
  }

  ${(props) =>
    css`
      background-image: url(${props.background});
    `}
`;

const VideoInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const VideoTheme = styled.span`
  color: var(--primary-color);
  font-weight: bold;
  font-size: 0.75rem;
`;

const VideoTitle = styled.h5`
  color: var(--gray-darkest);
`;

interface VideoPlayerCardProps {
  content: Content;
}

const VideoPlayerCard: React.FC<VideoPlayerCardProps> = ({ content }) => {
  const titleHeading = useRef<HTMLHeadingElement>(null);
  const themeSpan = useRef<HTMLSpanElement>(null);
  const { allThemes, changeSelectedContent, selectedContent } = useVideos();

  const handleClick = () => {
    changeSelectedContent(content);
  };

  const theme = useMemo(() => {
    return (allThemes || []).find((t) => t.content_id === content.themeId);
  }, [allThemes, content.themeId]);

  useEffect(() => {
    if (titleHeading.current) {
      clamp(titleHeading.current, { clamp: 3 });
    }

    if (themeSpan.current) {
      clamp(themeSpan.current, { clamp: 1 });
    }
  }, [titleHeading, themeSpan]);

  return (
    <VideoPlayerCardContainer
      onClick={handleClick}
      selected={selectedContent?.content_id === content.content_id}
    >
      <VideoThumb background={content.images.thumbnail}>
        <span className="duration">{secondsToTimeText(content.duration)}</span>
      </VideoThumb>
      <VideoInfo>
        {!!theme && <VideoTheme ref={themeSpan}>{theme?.name}</VideoTheme>}
        <VideoTitleAndWatchedContainer>
          <VideoTitle ref={titleHeading}>{content.name}</VideoTitle>
          <VideoWatched video={content} />
        </VideoTitleAndWatchedContainer>
      </VideoInfo>
    </VideoPlayerCardContainer>
  );
};

export default VideoPlayerCard;

import React, { useCallback } from 'react';
import styled from 'styled-components';
import ReactStars from 'react-stars';
import useVideos from 'src/pages/Videos/hooks/useVideos';
import { rateContent as rateContentService } from 'src/services/contents';
import showErrorMessage from 'src/helpers/showErrorMessage';
import SystemError from 'src/models/error';
import showSuccessMessage from 'src/helpers/showSuccessMessage';

const RateStarsContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    color: var(--secondary-color);
    font-weight: 500;
    font-size: 1rem;
  }
`;

const RateStars: React.FC = () => {
  const { selectedContent, reloadData } = useVideos();

  const rateContent = useCallback(
    async (newRating: number) => {
      if (selectedContent) {
        try {
          await rateContentService(selectedContent.content_id, newRating);

          showSuccessMessage('Vídeo avaliado!');

          await reloadData();
        } catch (error) {
          showErrorMessage(error as SystemError);
        }
      }
    },
    [reloadData, selectedContent]
  );

  return (
    <RateStarsContainer>
      {!!selectedContent ? (
        <>
          <span>Avalie: </span>
          <ReactStars
            count={5}
            size={24}
            half={false}
            onChange={rateContent}
            value={selectedContent?.content_user?.review_stars}
          />
        </>
      ) : (
        <></>
      )}
    </RateStarsContainer>
  );
};

export default RateStars;

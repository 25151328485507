import styled from 'styled-components';

const MyProfileContainer = styled.div`
  margin-top: 64px;
  margin-bottom: 116px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 396px;
  width: 100%;
  align-items: stretch;
`;

const Content = styled.div`
  flex: 1;
  padding-left: 106px;
  padding-right: 106px;
  display: flex;
  justify-content: center;

  .main-title, .subtitle, .section-title {
    color: var(--secondary-color);
  }

  .main-title, .section-title {
    line-height: 40px;
  }

  .main-title {
    font-size: 38px;
  }

  .subtitle {
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
  }

  .section-title {
    font-size: 24px;
  }

  @media (max-width: 1000px) {
    padding: 0 16px;
    flex-basis: 800px;
  }
`;

export { MyProfileContainer, Content };

import { pdf } from '@react-pdf/renderer';
import clamp from 'clamp-js';
import download from 'downloadjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BsDownload } from 'react-icons/bs';
import showErrorMessage from 'src/helpers/showErrorMessage';
import useAuth from 'src/hooks/useAuth';
import useLoading from 'src/hooks/useLoading';
import useWindowSize from 'src/hooks/useWindowSize';
import Certificate from 'src/models/certificate';
import SystemError from 'src/models/error';
import CertificateTemplate from 'src/pdf/templates/CertificateTemplate';
import {
  CertificateCardContainer,
  CertificateCardThumbnail,
  CertificateCardTitle,
  CertificateDownloadButton,
} from './style';

interface CertificateCardProps {
  certificate: Certificate;
}

const CertificateCard: React.FC<CertificateCardProps> = ({ certificate }) => {
  const title = useRef<HTMLHeadingElement>(null);
  const [hovered, setHovered] = useState(false);
  const { setIsLoading } = useLoading();
  const { user } = useAuth();
  const { windowWidth } = useWindowSize();

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const handleClick = useCallback(async () => {
    setIsLoading(true);

    try {
      const template = (
        <CertificateTemplate
          certificate={certificate}
          content={certificate?.content!}
          user={user!}
        />
      );

      const blob = await pdf(template).toBlob();

      download(
        blob,
        `Certificado-${certificate?.content?.name || 'Conteúdo'}.pdf`
      );
    } catch (error) {
      showErrorMessage(error as SystemError);
    }

    setIsLoading(false);
  }, [certificate, setIsLoading, user]);

  useEffect(() => {
    if (title.current) {
      clamp(title.current, { clamp: 2 });
    }
  }, []);

  return (
    <CertificateCardContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <CertificateCardThumbnail
        alt="certificate background"
        src={certificate.content?.images.thumbnail}
      />
      <CertificateCardTitle ref={title}>
        {certificate.content?.name}
      </CertificateCardTitle>
      <CertificateDownloadButton
        type="button"
        hovered={hovered || windowWidth <= 1000}
      >
        <BsDownload /> <span>Baixar Certificado</span>
      </CertificateDownloadButton>
    </CertificateCardContainer>
  );
};

export default CertificateCard;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SubHeader from 'src/components/SubHeader';
import SubHeaderTitle from 'src/components/SubHeader/SubHeaderTitle';
import Breadcrumb from 'src/components/Breadcrumb';
import usePartner from 'src/hooks/usePartner';
import specialtiesBanner from 'src/assets/specialties.png';
import Content from 'src/models/content';
import { getContentUsers as getContentUsersService } from 'src/services/contentUsers';
import {
  MyListContainer,
  SpecialtiesContainer,
  ContentsContainer,
  ContentsShowMoreButtonContainer,
  ContentsTitle,
  SpcecialtiesTitle,
  SpecialtiesShowMoreButtonContainer,
  TitleAndSpecialtyContainer,
  ContentesAndSpecialtiesDivider,
} from './style';
import Specialty from 'src/models/specialty';
import ContentUser from 'src/models/content-user';
import { SubHeaderDivider } from 'src/components/SubHeaderDivider';
import VideoCard from 'src/components/VideoCard';
import SpecialtyCard from 'src/components/SpecialtyCard';
import GoBack from 'src/components/GoBack';
import Pagination from 'src/components/Pagination';
import goToTop from 'src/helpers/go-to-top';
import Button from 'src/components/Button';
import { AiOutlineDown } from 'react-icons/ai';
import Select from 'src/components/Select';
import SelectOption from 'src/models/select-option';
import useLoading from 'src/hooks/useLoading';
import removeAccentuation from 'src/helpers/remove-accentuation';
import { useParams } from 'react-router-dom';

type ShowingOptions = 'both' | 'contents' | 'specialties';

type Orders = 'most_recent' | 'alpha';

const numberOfContentsPerPage = 12;
const firstPage = 1;

const orderOptions = [
  {
    label: 'Mais recentes',
    value: 'most_recent',
  },
  { label: 'Ordem alfabética', value: 'alpha' },
] as SelectOption[];

const MyList: React.FC = () => {
  const { showingContent } = useParams<'showingContent'>();
  const { setIsLoading } = useLoading();
  const { PartnerLink, partner, navigate } = usePartner();
  const [page, setPage] = useState(firstPage);
  const [showing, setShowing] = useState<ShowingOptions>('both');
  const [specialties, setSpecialties] = useState([] as Specialty[]);
  const [contents, setContents] = useState([] as Content[]);
  const [selectedOrder, setSelectedOrder] = useState<Orders>('alpha');

  const handleChangePage = useCallback((selectedPage: number) => {
    setPage(selectedPage);
    goToTop();
  }, []);

  const handleChangeShowing = useCallback(
    (showing: ShowingOptions) => {
      handleChangePage(firstPage);
      setShowing(showing);

      if (showing === 'both') {
        navigate(`/my-list`);
      } else {
        navigate(`/my-list/${showing}`);
      }
    },
    [handleChangePage, navigate]
  );

  const showContents = useCallback(() => {
    handleChangeShowing('contents');
  }, [handleChangeShowing]);

  const showSpecialties = useCallback(() => {
    handleChangeShowing('specialties');
  }, [handleChangeShowing]);

  const getSpecialtiesAndContents = useCallback(async () => {
    setIsLoading(true);

    const specialtiesAndContentUsers = (await getContentUsersService({
      relations: ['content'],
      book_mark: true,
    })) as ContentUser[];

    const specialtiesAndContents = (specialtiesAndContentUsers || []).map(
      (c) => ({ ...c.content })
    ) as (Specialty | Content)[];

    const contents = (specialtiesAndContents || []).filter(
      (c) => c.flag === 'content'
    ) as Content[];

    const specialties = (specialtiesAndContents || []).filter(
      (c) => c.flag === 'specialty'
    ) as Specialty[];

    setContents(contents);
    setSpecialties(specialties);

    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    getSpecialtiesAndContents();
  }, [getSpecialtiesAndContents]);

  const contentsToShow = useMemo(() => {
    let result = [] as Content[];

    if (showing === 'both') {
      result = [...(contents || [])].slice(0, numberOfContentsPerPage);
    } else if (showing === 'contents') {
      result = [...(contents || [])].slice(
        numberOfContentsPerPage * (page - 1),
        numberOfContentsPerPage * page
      );
    }

    if (selectedOrder === 'most_recent') {
      result = (result || []).sort((c1, c2) => {
        const c1Date = new Date(c1.created_at);
        const c2Date = new Date(c2.created_at);

        return c1Date.getTime() > c2Date.getTime() ? -1 : 1;
      });
    } else {
      result = (result || []).sort((c1, c2) => {
        return removeAccentuation(c1.name) > removeAccentuation(c2.name)
          ? -1
          : 1;
      });
    }

    return result;
  }, [contents, page, selectedOrder, showing]);

  const specialtiesToShow = useMemo(() => {
    let result = [] as Specialty[];

    if (showing === 'both') {
      result = [...(specialties || [])].slice(0, numberOfContentsPerPage);
    } else if (showing === 'specialties') {
      result = [...(specialties || [])].slice(
        numberOfContentsPerPage * (page - 1),
        numberOfContentsPerPage * page
      );
    }

    if (selectedOrder === 'most_recent') {
      result = (result || []).sort((c1, c2) => {
        const c1Date = new Date(c1.created_at);
        const c2Date = new Date(c2.created_at);

        return c1Date.getTime() > c2Date.getTime() ? -1 : 1;
      });
    } else {
      result = (result || []).sort((c1, c2) => {
        return removeAccentuation(c1.name) > removeAccentuation(c2.name)
          ? -1
          : 1;
      });
    }

    return result;
  }, [page, selectedOrder, showing, specialties]);

  useEffect(() => {
    if (showingContent) {
      setShowing(showingContent as ShowingOptions);
    } else {
      setShowing('both');
    }
  }, [showingContent]);

  return (
    <MyListContainer>
      <SubHeader background={partner?.banner?.mylist || specialtiesBanner}>
        <Breadcrumb
          crumbs={[
            <PartnerLink className="home" to="/home">
              Início
            </PartnerLink>,
            <PartnerLink to="/my-list">Minha Lista</PartnerLink>,
          ]}
        />
        <SubHeaderDivider />
        <GoBack />
        <SubHeaderTitle title="Minha Lista" />
      </SubHeader>
      {(showing === 'both' || showing === 'contents') && (
        <ContentsContainer className="max-content">
          <TitleAndSpecialtyContainer>
            <ContentsTitle>Vídeos ({contents.length || 0})</ContentsTitle>

            {showing === 'contents' ? (
              <Select
                label="Classificar por"
                options={orderOptions}
                value={orderOptions.find((o) => o.value === selectedOrder)}
                setValue={(order) =>
                  setSelectedOrder((order || {}).value as Orders)
                }
              />
            ) : (
              <span></span>
            )}
          </TitleAndSpecialtyContainer>

          {!!contentsToShow?.length &&
            contentsToShow.map((c) => (
              <VideoCard key={c.content_id} video={c} />
            ))}

          {showing === 'both' ? (
            <ContentsShowMoreButtonContainer>
              <Button type="button" onClick={showContents}>
                <span>Ver Mais</span> <AiOutlineDown />
              </Button>
            </ContentsShowMoreButtonContainer>
          ) : (
            <Pagination
              onPageChange={handleChangePage}
              pageCount={Math.ceil(
                (contents || []).length / numberOfContentsPerPage
              )}
            />
          )}
        </ContentsContainer>
      )}

      {showing === 'both' && (
        <ContentesAndSpecialtiesDivider className="max-content" />
      )}

      {(showing === 'both' || showing === 'specialties') && (
        <SpecialtiesContainer className="max-content">
          <TitleAndSpecialtyContainer>
            <SpcecialtiesTitle>
              Especialidades ({specialties.length || 0})
            </SpcecialtiesTitle>

            {showing === 'specialties' ? (
              <Select
                label="Classificar por"
                options={orderOptions}
                value={orderOptions.find((o) => o.value === selectedOrder)}
                setValue={(order) =>
                  setSelectedOrder((order || {}).value as Orders)
                }
              />
            ) : (
              <span></span>
            )}
          </TitleAndSpecialtyContainer>

          {!!specialtiesToShow?.length &&
            specialtiesToShow.map((s) => (
              <SpecialtyCard key={s.content_id} specialty={s} />
            ))}

          {showing === 'both' ? (
            <SpecialtiesShowMoreButtonContainer>
              <Button type="button" onClick={showSpecialties}>
                <span>Ver Mais</span> <AiOutlineDown />
              </Button>
            </SpecialtiesShowMoreButtonContainer>
          ) : (
            <Pagination
              onPageChange={handleChangePage}
              pageCount={Math.ceil(
                (specialties || []).length / numberOfContentsPerPage
              )}
            />
          )}
        </SpecialtiesContainer>
      )}
    </MyListContainer>
  );
};

export default MyList;

import styled from 'styled-components';

const PersonalDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: start;
  max-width: 932px;
  flex: 1;
`;

const NameAndPicture = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  img {
    border-radius: 50%;
    height: 72px;
    aspect-ratio: 1/1;
  }

  .right-side {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: start;

    h2 {
      color: var(--secondary-color);
      font-weight: 600;
      font-size: 32px;
      line-height: 32px;
    }
    
    .change-picture  {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      border: none;

      svg {
        width: 14px;
        height: 12px;
      }

      span {
        color: var(--primary-color);
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: start;
  width: 100%;
  
  .line {
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 100%;
  
    & > .inner-line {
      flex: 4;
      display: flex;
      flex-direction: row;
      gap: 40px;
    }
  }
  
  .address-title {
    margin-top: 24px;
  }

  button {
    margin-top: 24px;
  }
`;

export { PersonalDataContainer, NameAndPicture, Form };

import Specialty from 'src/models/specialty';
import { getSpecialty as getSpecialtyService } from 'src/services/specialties';

const getSpecialtyProgress = async (
  specialty: Specialty
): Promise<Specialty> => {
  const fullSpecialty =
    (await getSpecialtyService(specialty.content_id)) || ({} as Specialty);

  const fullSpecialtyThemes = fullSpecialty.children || [];

  const fullSpecialtyContents =
    fullSpecialtyThemes.map((t) => t.children || []).flat() || [];

  const numberOfFinishedContents = (
    fullSpecialtyContents.filter((c) => !!c.content_user?.finish_at) || []
  ).length;

  const numberOfContents = fullSpecialtyContents.length;

  const progress = (numberOfFinishedContents / numberOfContents) * 100;

  return { ...fullSpecialty, progress };
};

export default getSpecialtyProgress;

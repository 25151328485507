import { CardWrapper } from './style';
import { Button } from 'src/pages/LandingPage/style';
import { useNavigate } from 'react-router-dom';

interface PlanCardProps {
  planType: string;
  price1: string;
  price2: string;
  buttonText: string;
  text: string;
  liText: string[];
  path: string;
}

const PlanCard: React.FC<PlanCardProps> = ({
  planType,
  price1,
  price2,
  text,
  liText,
  buttonText,
  path,
}) => {
  const navigate = useNavigate();
  return (
    <CardWrapper>
      <h2>{planType}</h2>
      <div>
        {price2 ? <h3>{price2}</h3> : null}
        <h1>{price1}</h1>
      </div>
      <p>{text}</p>
      <ul>
        {liText.map((text, index) => (
          <li key={text}>
            <span>{text}</span>
          </li>
        ))}
      </ul>
      <Button onClick={() => navigate(`/${path}`)}>{buttonText}</Button>
    </CardWrapper>
  );
};

export default PlanCard;

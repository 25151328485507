import { useCallback, useEffect, useState } from 'react';
import arrayShuffle from 'array-shuffle';
import HomeContext from 'src/contexts/HomeContext';
import Content from 'src/models/content';
import { getContentUsers as getContentUsersService } from 'src/services/contentUsers';
import { getContents as getContentsService } from 'src/services/contents';
import useLoading from 'src/hooks/useLoading';

const HomeContextProvider: React.FC = ({ children }) => {
  const [contentsInProgress, setContentsInProgress] = useState<Content[]>();
  const [featuredContents, setFeaturedContents] = useState<Content[]>();
  const [suggestedContents, setSuggestedContents] = useState<Content[]>();
  const [newContents, setNewContents] = useState<Content[]>();
  const { setIsLoading } = useLoading();

  const getContentsProgress = useCallback(async () => {
    const contentsProgress = await getContentUsersService({
      relations: ['content'],
    });

    const contentsWatched = (contentsProgress || []).filter(
      (c) => !!c.content_view && c.content_view !== null && !c.finish_at
    );

    const contents = (contentsWatched || []).map((c) => {
      const progress =
        ((c.content_view || 0) / (c.content?.duration || 1)) * 100;
      return { ...(c.content! || {}), progress };
    });

    setContentsInProgress(contents);
  }, []);

  const getFeaturedContents = useCallback(async () => {
    const contents = (await getContentsService()).data.filter(
      (content) => content.is_highlight
    );
    setFeaturedContents(contents);
  }, []);

  const getSuggestedContents = useCallback(async () => {
    const { data: contents } = await getContentsService({
      limit: 20,
      order_by: 'most_viewed',
    });
    const shuffled = arrayShuffle(contents);
    setSuggestedContents(shuffled);
  }, []);

  const getNewContents = useCallback(async () => {
    const { data: contents } = await getContentsService({
      order_by: 'updated_at',
      sort_by: 'DESC',
    });

    setNewContents(contents);
  }, []);

  const getContents = useCallback(async () => {
    setIsLoading(true);

    await getContentsProgress();
    await getFeaturedContents();
    await getSuggestedContents();
    await getNewContents();

    setIsLoading(false);
  }, [
    getContentsProgress,
    getFeaturedContents,
    getNewContents,
    getSuggestedContents,
    setIsLoading,
  ]);

  useEffect(() => {
    getContents();
  }, [getContents]);

  return (
    <HomeContext.Provider
      value={{
        contentsInProgress,
        featuredContents,
        suggestedContents,
        newContents,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export default HomeContextProvider;

import { useCallback, useEffect, useState } from 'react';
import LandingContext from 'src/contexts/LandingContext';
import Content from 'src/models/content';
import { getLandingContents as getContentsService } from 'src/services/landing';

const LandingContextProvider: React.FC = ({ children }) => {
  const [contents, setContents] = useState([] as Content[]);

  const getContents = useCallback(async () => {
    const { data: contents } = await getContentsService({
      relations: ['authors', 'children'],
    });
    setContents(contents);
  }, []);

  useEffect(() => {
    getContents();
  }, [getContents]);

  return (
    <LandingContext.Provider value={{ contents }}>
      {children}
    </LandingContext.Provider>
  );
};

export default LandingContextProvider;

import React from 'react';
import { InputContainer } from './style';

interface FormInputProps {
  label: string;
  onChange: (value: string) => void;
  value?: string;
  type?: string;
  flex?: number;
  disabled?: boolean;
  required?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({ label, onChange, value, flex, type, disabled, required }) => {

  return (
    <InputContainer flex={flex || 1} disabled={disabled || false}>
      <label>{label}</label>
      <input onChange={(e) => onChange(e.target?.value)} disabled={disabled} required={required} type={type || "text"} value={value} />
    </InputContainer>
  );
};

export default FormInput;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import SubHeader from 'src/components/SubHeader';
import usePartner from 'src/hooks/usePartner';
import {
  ContentsContainer,
  ContentsTitle,
  NoResultFound,
  ResultDescription,
  SearchContainer,
  SpecialtiesContainer,
  SpecialtiesTitle,
} from './style';
import searchDefaultImage from 'src/assets/search-default-image.png';
import SubHeaderTitle from 'src/components/SubHeader/SubHeaderTitle';
import useSearch from 'src/hooks/useSearch';
import { getSpecialties as getSpecialtiesService } from 'src/services/specialties';
import { getContents as getContentsService } from 'src/services/contents';
import Specialty from 'src/models/specialty';
import Content from 'src/models/content';
import VideoCard from 'src/components/VideoCard';
import SpecialtyCard from 'src/components/SpecialtyCard';
import useLoading from 'src/hooks/useLoading';

interface ExtendedWindow extends Window {
  searchTimeoutId: number;
}

declare let window: ExtendedWindow;

const Search: React.FC = () => {
  const { searchText } = useSearch();
  const { setIsLoading } = useLoading();
  const { partner, PartnerLink } = usePartner();
  const [specialties, setSpecialties] = useState<Specialty[] | undefined>();
  const [contents, setContents] = useState<Content[] | undefined>();

  const getSpecialtiesAndContents = useCallback(async () => {
    setIsLoading(true);

    if (searchText) {
      const { data: specialties } = await getSpecialtiesService({
        name: searchText,
      });
      setSpecialties(specialties);
      const { data: contents } = await getContentsService({
        name: searchText,
      });
      setContents(contents);
    } else {
      setSpecialties([] as Specialty[]);
      setContents([] as Content[]);
    }

    setIsLoading(false);
  }, [searchText, setIsLoading]);

  const numberOfResults = useMemo(() => {
    return (specialties || []).length + (contents || []).length;
  }, [contents, specialties]);

  useEffect(() => {
    window.clearTimeout(window.searchTimeoutId);

    window.searchTimeoutId = window.setTimeout(async () => {
      getSpecialtiesAndContents();
    }, 300);
  }, [getSpecialtiesAndContents]);

  return (
    <SearchContainer>
      <SubHeader background={partner?.banner.search || searchDefaultImage}>
        <Breadcrumb
          crumbs={[
            <PartnerLink to="/home">Início</PartnerLink>,
            <span>Pesquisa</span>,
          ]}
        />
        <SubHeaderTitle title="Pesquisa" />
      </SubHeader>
      <ResultDescription className="max-content">
        {numberOfResults} resultado{numberOfResults > 1 ? 's' : ''} para{' '}
        <span className="search-text">"{searchText}"</span>
      </ResultDescription>
      <ContentsContainer className="max-content">
        <ContentsTitle>Vídeos</ContentsTitle>
        {!!contents?.length ? (
          contents.map((c) => <VideoCard video={c} key={c.content_id} />)
        ) : (
          <NoResultFound>
            Não foram encontrados vídeos para sua pesquisa.
          </NoResultFound>
        )}
      </ContentsContainer>
      <SpecialtiesContainer className="max-content">
        <SpecialtiesTitle>Especialidades</SpecialtiesTitle>
        {!!specialties?.length ? (
          specialties.map((s) => (
            <SpecialtyCard specialty={s} key={s.content_id} />
          ))
        ) : (
          <NoResultFound>
            Não foram encontradas especialidades para sua pesquisa.
          </NoResultFound>
        )}
      </SpecialtiesContainer>
    </SearchContainer>
  );
};

export default Search;

import React, { useContext } from 'react';
import { ReactComponent as LogoError } from 'src/assets/logoError.svg';
import SubHeader from 'src/components/SubHeader';
import Breadcrumb from 'src/components/Breadcrumb';
import PartnerContext from 'src/contexts/PartnerContext';
import errorBanner from 'src/assets/landing.png';
import { ErrorMessageContainer, ErrorPageContainer } from './style';
import ButtonRed from 'src/components/RedButton';
import { SubHeaderDivider } from 'src/components/SubHeaderDivider';
import GoBack from 'src/components/GoBack';

interface ErrorPageProps {
  notFound?: boolean;
  somethingWrong?: boolean;
  accessDenied?: boolean;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  notFound,
  somethingWrong,
  accessDenied,
}) => {
  const { PartnerLink } = useContext(PartnerContext);

  return (
    <ErrorPageContainer>
      <SubHeader background={errorBanner}>
        <Breadcrumb
          crumbs={[
            <PartnerLink className="home">Início</PartnerLink>,
            <PartnerLink>Erro</PartnerLink>,
          ]}
        />

        <SubHeaderDivider />

        <GoBack />
      </SubHeader>
      <ErrorMessageContainer>
        <LogoError />
        {notFound && (
          <>
            <h1>
              Ops, <span>não encontramos</span> essa página.
            </h1>
            <p>Erro 404: Essa página que você procura não existe.</p>
          </>
        )}
        {accessDenied && (
          <>
            <h1>
              Ops, <span>algo deu errado</span>
            </h1>
            <p>A página que você procura não está disponível.</p>
          </>
        )}
        {somethingWrong && (
          <>
            <h1>
              Ops, <span>algo deu errado</span>
            </h1>
            <p>O carregamento dessa página falhou.</p>
          </>
        )}
        <div>
          <ButtonRed>Voltar a página anterior</ButtonRed>
        </div>
      </ErrorMessageContainer>
    </ErrorPageContainer>
  );
};

export default ErrorPage;

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.min.css';
import { SwiperContainer } from './style';
import SwiperCore, { Pagination, Navigation } from 'swiper';

SwiperCore.use([Pagination, Navigation]);

interface CarouselProps {
  items: JSX.Element[];
}

const Carousel: React.FC<CarouselProps> = ({ items }) => {
  return (
    <SwiperContainer>
      <Swiper
        slidesPerView={4.15}
        spaceBetween={24}
        slidesPerGroup={1}
        navigation={true}
        mousewheel={true}
        className="mySwiper"
      >
        {items?.map((item) => (
          <SwiperSlide key={item.key}>{item}</SwiperSlide>
        ))}
      </Swiper>
    </SwiperContainer>
  );
};

export default Carousel;

import Button from 'src/components/Button';
import styled, { css } from 'styled-components';

const MobileMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 10;
`;

const MobileMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--secondary-color);
  padding: 16px;
  overflow: hidden;
`;

const MobileMenuList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const MobileMenuCloseButton = styled(Button)``;

interface MobileMenuItemProps {
  active: boolean;
}

const MobileMenuItem = styled(Button)<MobileMenuItemProps>`
  background: var(--secondary-color);
  filter: brightness(0.8);
  padding: 12px;
  border-radius: 4px;
  color: white;
  font-size: 1em;

  ${(props) =>
    props.active &&
    css`
      color: var(--primary-color);
    `}
`;

const MobileMenuSearchContainer = styled.form`
  display: flex;
  gap: 4px;
`;

const MobileMenuUserContextMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MobileMenuSearchInput = styled.input`
  outline: var(--primary-color);
  border-radius: 15px;
  padding: 8px;
`;

const MobileMenuSearchButton = styled(Button)`
  color: white;
`;

const MobileMenuUserContextMenuOpenButton = styled(Button)`
  background: var(--primary-color);
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 1em;
  padding: 16px;
  border-radius: 4px;

  & > span {
    flex: 1;
    text-align: left;
  }
`;

interface MobileMenuUserContextMenuContentProps {
  opened: boolean;
}

const MobileMenuUserContextMenuContent = styled.div<MobileMenuUserContextMenuContentProps>`
  height: 0;
  overflow: hidden;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${(props) =>
    props.opened &&
    css`
      height: 140px;
      margin-top: 8px;
    `}
`;

const MobileMenuDivider = styled.hr`
  margin: 8px 0;
  border: none;
  border-top: solid 2px white;
  width: 120%;
  transform: translateX(-10%);
`;

export {
  MobileMenuContainer,
  MobileMenuContent,
  MobileMenuList,
  MobileMenuItem,
  MobileMenuSearchContainer,
  MobileMenuUserContextMenuContainer,
  MobileMenuSearchInput,
  MobileMenuSearchButton,
  MobileMenuUserContextMenuOpenButton,
  MobileMenuUserContextMenuContent,
  MobileMenuCloseButton,
  MobileMenuDivider,
};

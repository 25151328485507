import styled from 'styled-components';

const AppLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
`;

const ContentContainer = styled.div`
  flex: 1;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

export { AppLayoutContainer, ContentContainer };

import React, { useState } from 'react';
import FormInput from 'src/components/FormInput';
import { FormButton } from '../FormButton/style'
import { SecurityContainer } from './style';

const Security: React.FC = () => {
  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('');
  const [editing, setEditing] = useState<boolean>(false);

  return (
    <SecurityContainer
      onSubmit={(e) => {
        e.preventDefault();
        if (editing) {
          setEditing(false);
        } else {
          setEditing(true);
        }
      }}
    >
      <h1 className="main-title">Segurança</h1>
      <h4 className="subtitle">Edite sua senha a qualquer momento para manter sua segurança!</h4>
      {editing &&
        <div className="input-container">
          <div className="input-inner-container">
            <FormInput onChange={value => setPassword(value)} value={password} label="Senha atual" type="password" />
            <FormInput onChange={value => setNewPassword(value)} value={newPassword} label="Nova senha" type="password" />
          </div>
          <FormInput onChange={value => setNewPasswordConfirmation(value)} value={newPasswordConfirmation} label="Confirme a nova senha" type="password" />
        </div>
      }
      <FormButton type="submit">{editing ? 'SALVAR ALTERAÇÕES' : 'EDITAR MEUS DADOS'}</FormButton>
    </SecurityContainer>
  );
};

export default Security;

import httpClient from 'src/http-client';
import Specialty from 'src/models/specialty';
import Paginated from 'src/models/paginated';

const getSpecialties = async (filters?: any) => {
  return (
    await httpClient.get<Paginated<Specialty>>(`/contents`, {
      params: {
        ...filters,
        flag: ['specialty'],
        relations: ['segmentations', ...((filters || {}).relations || [])],
      },
    })
  ).data;
};

const getSpecialty = async (specialtyId: string) => {
  const specialty = (
    await httpClient.get<Specialty>(`/contents/${specialtyId}`, {
      params: {
        relations: ['children', 'authors', 'content_user', 'extra_materials'],
      },
    })
  ).data;

  if (specialty.children?.length) {
    for (let theme of specialty.children) {
      if (theme.children?.length) {
        for (let content of theme.children) {
          content.themeId = theme.content_id;
        }
      }
    }
  }

  return specialty;
};

const createSpecialty = async (newSpecialty: Partial<Specialty>) => {
  return (
    await httpClient.post(`/contents`, { ...newSpecialty, flag: 'specialty' })
  ).data;
};

const updateSpecialty = async (
  specialtyId: string,
  newSpecialty: Partial<Specialty>
) => {
  delete newSpecialty.content_id;
  delete newSpecialty.created_at;
  delete newSpecialty.updated_at;
  delete newSpecialty.segmentation_items;

  return (
    await httpClient.put(`/contents/${specialtyId}`, {
      ...newSpecialty,
      flag: 'specialty',
    })
  ).data;
};

const deleteSpecialty = async (specialtyId: string) => {
  await httpClient.delete(`/contents/${specialtyId}`);
};

const toggleSpecialtyActive = async (
  specialtyId: string,
  shouldActive: boolean
) => {
  return (
    await httpClient.patch(`/contents/${specialtyId}/active`, {
      is_active: shouldActive,
    })
  ).data;
};

const addToListSpecialty = async (specialtyId: string, addToList: boolean) => {
  return (
    await httpClient.patch(`/content-users/${specialtyId}/bookmark`, {
      book_mark: addToList,
    })
  ).data;
};

const startSpecialty = async (specialtyId: string) => {
  return await (
    await httpClient.post(`/content-users/${specialtyId}/start`)
  ).data;
};

const finishSpecialty = async (specialtyId: string) => {
  return await (
    await httpClient.post(`/content-users/${specialtyId}/finish`)
  ).data;
};

export {
  getSpecialties,
  getSpecialty,
  createSpecialty,
  updateSpecialty,
  deleteSpecialty,
  toggleSpecialtyActive,
  addToListSpecialty,
  startSpecialty,
  finishSpecialty,
};

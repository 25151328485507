import styled, { css } from 'styled-components';

const VideoCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  height: 240px;
  padding: 24px 8px;
  background: rgb(0, 0, 0);
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  @keyframes from-down-to-up-video {
    from {
      transform: translateY(20%);
    }
    to {
      transform: translateY(0%);
    }
  }
`;

interface VideoTitleProps {
  hovered: boolean;
}

const VideoTitle = styled.h4<VideoTitleProps>`
  color: var(--white);
  font-size: 1.4em;
  z-index: 2;
  text-align: left;

  ${(props) =>
    props.hovered &&
    css`
      animation: from-down-to-up-video 0.5s;
    `}
`;

const VideoTheme = styled.span`
  color: var(--white);
  background: rgba(17, 9, 43, 0.9);
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 8px;
  white-space: nowrap;
  min-width: 80px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const VideoThemesContainer = styled.div`
  height: 40px;
  padding: 4px 0;
  overflow: hidden;
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  gap: 8px;
  white-space: nowrap;
  z-index: 2;
`;

const VideoDescription = styled.p`
  animation: from-down-to-up-video 0.5s;
  margin-top: 8px;
  color: var(--white);
  font: 1em;
  z-index: 2;
  text-align: left;
`;

interface VideoThumbnailProps {
  hovered: boolean;
}

const VideoThumbnail = styled.img<VideoThumbnailProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  filter: brightness(80%);
  transition: all 0.5s;

  ${(props) =>
    props.hovered &&
    css`
      transform: scale(1.2);
    `}
`;

const ContinueWatching = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  animation: from-down-to-up-video 0.5s;
  margin-top: 8px;
  color: var(--white);
  font: 1em;
  z-index: 2;

  & > .icon {
    margin-top: 6px;
  }

  & > .text {
    flex: 1;
    font-size: 1.1em;
    font-weight: 500;
    text-align: left;
  }

  & > .percent {
    font-weight: bold;
  }
`;

const ReWatch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  animation: from-down-to-up-video 0.5s;
  margin-top: 8px;
  color: var(--white);
  font: 1em;
  z-index: 2;

  & > .icon {
    margin-top: 6px;
  }

  & > .text {
    flex: 1;
    font-size: 1.1em;
    font-weight: 500;
  }

  & > .percent {
    font-weight: bold;
  }
`;

interface ProgressContainerProps {
  progress: number;
}

const ProgressContainer = styled.div<ProgressContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 8px;
  background: rgba(255, 255, 255, 0.2);
  z-index: 2;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 8px;

  & > .progress {
    height: 100%;
    background: var(--primary-color);

    ${(props) =>
      css`
        width: ${props.progress}%;
      `}
  }
`;

export {
  VideoCardContainer,
  VideoTitle,
  VideoTheme,
  VideoThemesContainer,
  VideoDescription,
  VideoThumbnail,
  ContinueWatching,
  ProgressContainer,
  ReWatch,
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SubHeader from 'src/components/SubHeader';
import usePartner from 'src/hooks/usePartner';
import progressBanner from 'src/assets/progress.png';
import { SubHeaderDivider } from 'src/components/SubHeaderDivider';
import SubHeaderTitle from 'src/components/SubHeader/SubHeaderTitle';
import Breadcrumb from 'src/components/Breadcrumb';
import {
  ContentsContainer,
  ContentsTitle,
  FinishedContainer,
  FinishedTitle,
  InProgressAndFinishedDivider,
  InProgressContainer,
  InProgressTitle,
  MyProgressContainer,
  PaginationContainer,
  ShowMoreButton,
  SpecialtiesContainer,
  SpecialtiesTitle,
  TitleAndOrderOrShowMoreContainer,
} from './style';
import useLoading from 'src/hooks/useLoading';
import { getContentUsers as getContentUsersService } from 'src/services/contentUsers';
import Content from 'src/models/content';
import Specialty from 'src/models/specialty';
import Pagination from 'src/components/Pagination';
import VideoCard from 'src/components/VideoCard';
import SpecialtyCard from 'src/components/SpecialtyCard';
import getSpecialtyProgress from 'src/helpers/get-specialty-progress';
import getContentProgress from 'src/helpers/get-content-progress';
import goToTop from 'src/helpers/go-to-top';
import Select from 'src/components/Select';
import SelectOption from 'src/models/select-option';
import GoBack from 'src/components/GoBack';
import { AiOutlineRight } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import removeAccentuation from 'src/helpers/remove-accentuation';

const orderOptions = [
  {
    label: 'Mais recentes',
    value: 'most_recent',
  },
  { label: 'Ordem alfabética', value: 'alpha' },
] as SelectOption[];

const firstPage = 1;

const numberOfShowingContentsOrSpecialtiesOnBoth = 3;

const numberOfContentsOrSpecialtiesPerPage = 12;

type ShowingContent =
  | 'contentsInProgress'
  | 'specialtiesInProgress'
  | 'contentsFinished'
  | 'specialtiesFinished'
  | 'both';

interface ContentsAndSpecialtiesInProgress {
  specialties: Specialty[];
  contents: Content[];
}

interface ContentsAndSpecialtiesFinished {
  specialties: Specialty[];
  contents: Content[];
}

interface ContentsAndSpecialtiesInProgressOrFinished {
  inProgress?: ContentsAndSpecialtiesInProgress;
  finished?: ContentsAndSpecialtiesFinished;
}

const MyProgress: React.FC = () => {
  const { showingContent } = useParams<'showingContent'>();
  const { partner, PartnerLink, navigate } = usePartner();
  const { setIsLoading } = useLoading();
  const [page, setPage] = useState(firstPage);
  const [selectedOrder, setSelectedOrder] = useState('alpha');
  const [showing, setShowing] = useState<ShowingContent>('both');
  const [
    contentsAndSpecialtiesInProgressOrFinished,
    setContentsAndSpecialtiesInProgressOrFinished,
  ] = useState({} as ContentsAndSpecialtiesInProgressOrFinished);

  const handleChangeOrder = useCallback((order: SelectOption | undefined) => {
    goToTop();
    setSelectedOrder((order || {}).value || 'alpha');
  }, []);

  const handleChangePage = useCallback((selectedPage: number) => {
    goToTop();
    setPage(selectedPage);
  }, []);

  const handleChangeShowing = useCallback(
    (showing: ShowingContent) => {
      handleChangePage(firstPage);
      setShowing(showing);

      if (showing === 'both') {
        navigate(`/my-progress`);
      } else {
        navigate(`/my-progress/${showing}`);
      }
    },
    [handleChangePage, navigate]
  );

  const getContentsAndSpecialtiesInProgressOrFinished =
    useCallback(async () => {
      setIsLoading(true);

      const contentUsers =
        (await getContentUsersService({ relations: ['content'] })) || [];

      const inProgress = contentUsers.filter(
        (c) => !!c.start_at && !c.finish_at
      );

      const contentsOrSpecialtiesInProgress =
        inProgress.map((c) => c.content || ({} as Content | Specialty)) ||
        ([] as (Content | Specialty)[]);

      const specialtiesInProgress = await Promise.all(
        (
          contentsOrSpecialtiesInProgress.filter(
            (cs) => cs.flag === 'specialty'
          ) as Specialty[]
        ).map(async (s) => await getSpecialtyProgress(s))
      );

      const contentsInProgress = await Promise.all(
        (
          contentsOrSpecialtiesInProgress.filter(
            (cs) => cs.flag === 'content'
          ) as Content[]
        ).map(async (c) => await getContentProgress(c))
      );

      const finished = contentUsers.filter(
        (c) => !!c.start_at && !!c.finish_at
      );

      const contentsOrSpecialtiesFinished =
        finished.map((c) => c.content || ({} as Content | Specialty)) ||
        ([] as (Content | Specialty)[]);

      const specialtiesFinished = contentsOrSpecialtiesFinished.filter(
        (cs) => cs.flag === 'specialty'
      ) as Specialty[];

      const contentsFinished = contentsOrSpecialtiesFinished.filter(
        (cs) => cs.flag === 'content'
      ) as Content[];

      setContentsAndSpecialtiesInProgressOrFinished({
        finished: {
          contents: contentsFinished,
          specialties: specialtiesFinished,
        },
        inProgress: {
          contents: contentsInProgress,
          specialties: specialtiesInProgress,
        },
      });

      setIsLoading(false);
    }, [setIsLoading]);

  const contentsInProgress = useMemo(() => {
    const inProgressContents =
      contentsAndSpecialtiesInProgressOrFinished?.inProgress?.contents || [];

    let contents = [] as Content[];
    if (showing === 'both') {
      contents = [...inProgressContents].slice(
        0,
        numberOfShowingContentsOrSpecialtiesOnBoth
      );
    } else if (showing === 'contentsInProgress') {
      contents = [...inProgressContents].slice(
        (page - 1) * numberOfContentsOrSpecialtiesPerPage,
        page * numberOfContentsOrSpecialtiesPerPage
      );
    } else {
      contents = [] as Content[];
    }

    if (selectedOrder === 'most_recent') {
      contents = (contents || []).sort((c1, c2) => {
        const c1Date = new Date(c1.created_at);
        const c2Date = new Date(c2.created_at);

        return c1Date.getTime() > c2Date.getTime() ? -1 : 1;
      });
    } else {
      contents = (contents || []).sort((c1, c2) => {
        return removeAccentuation(c1.name) > removeAccentuation(c2.name)
          ? -1
          : 1;
      });
    }

    return contents;
  }, [
    contentsAndSpecialtiesInProgressOrFinished?.inProgress?.contents,
    page,
    selectedOrder,
    showing,
  ]);

  const specialtiesInProgress = useMemo(() => {
    const inProgressSpecialties =
      contentsAndSpecialtiesInProgressOrFinished?.inProgress?.specialties || [];

    let specialties = [] as Specialty[];
    if (showing === 'both') {
      specialties = [...inProgressSpecialties].slice(
        0,
        numberOfShowingContentsOrSpecialtiesOnBoth
      );
    } else if (showing === 'specialtiesInProgress') {
      specialties = [...inProgressSpecialties].slice(
        (page - 1) * numberOfContentsOrSpecialtiesPerPage,
        page * numberOfContentsOrSpecialtiesPerPage
      );
    } else {
      specialties = [] as Specialty[];
    }

    if (selectedOrder === 'most_recent') {
      specialties = (specialties || []).sort((s1, s2) => {
        const s1Date = new Date(s1.created_at);
        const s2Date = new Date(s2.created_at);

        return s1Date.getTime() > s2Date.getTime() ? -1 : 1;
      });
    } else {
      specialties = (specialties || []).sort((s1, s2) => {
        return removeAccentuation(s1.name) > removeAccentuation(s2.name)
          ? -1
          : 1;
      });
    }

    return specialties;
  }, [
    contentsAndSpecialtiesInProgressOrFinished?.inProgress?.specialties,
    page,
    selectedOrder,
    showing,
  ]);

  const numberOfInProgressSpecialties = useMemo(() => {
    return (
      contentsAndSpecialtiesInProgressOrFinished?.inProgress?.specialties
        ?.length || 0
    );
  }, [
    contentsAndSpecialtiesInProgressOrFinished?.inProgress?.specialties?.length,
  ]);

  const numberOfInProgressContents = useMemo(() => {
    return (
      contentsAndSpecialtiesInProgressOrFinished?.inProgress?.contents
        ?.length || 0
    );
  }, [
    contentsAndSpecialtiesInProgressOrFinished?.inProgress?.contents?.length,
  ]);

  const numberOfInProgressContentsOrSpecialties = useMemo(() => {
    return numberOfInProgressSpecialties + numberOfInProgressContents;
  }, [numberOfInProgressContents, numberOfInProgressSpecialties]);

  const contentsFinished = useMemo(() => {
    const finishedContents =
      contentsAndSpecialtiesInProgressOrFinished?.finished?.contents || [];

    let contents = [] as Content[];
    if (showing === 'both') {
      contents = [...finishedContents].slice(
        0,
        numberOfShowingContentsOrSpecialtiesOnBoth
      );
    } else if (showing === 'contentsFinished') {
      contents = [...finishedContents].slice(
        (page - 1) * numberOfContentsOrSpecialtiesPerPage,
        page * numberOfContentsOrSpecialtiesPerPage
      );
    } else {
      contents = [] as Content[];
    }

    if (selectedOrder === 'most_recent') {
      contents = (contents || []).sort((c1, c2) => {
        const c1Date = new Date(c1.created_at);
        const c2Date = new Date(c2.created_at);

        return c1Date.getTime() > c2Date.getTime() ? -1 : 1;
      });
    } else {
      contents = (contents || []).sort((c1, c2) => {
        return removeAccentuation(c1.name) > removeAccentuation(c2.name)
          ? -1
          : 1;
      });
    }

    return contents;
  }, [
    contentsAndSpecialtiesInProgressOrFinished?.finished?.contents,
    page,
    selectedOrder,
    showing,
  ]);

  const specialtiesFinished = useMemo(() => {
    const finishedSpecialties =
      contentsAndSpecialtiesInProgressOrFinished?.finished?.specialties || [];

    let specialties = [] as Specialty[];
    if (showing === 'both') {
      specialties = [...finishedSpecialties].slice(
        0,
        numberOfShowingContentsOrSpecialtiesOnBoth
      );
    } else if (showing === 'specialtiesFinished') {
      specialties = [...finishedSpecialties].slice(
        (page - 1) * numberOfContentsOrSpecialtiesPerPage,
        page * numberOfContentsOrSpecialtiesPerPage
      );
    } else {
      specialties = [] as Specialty[];
    }

    if (selectedOrder === 'most_recent') {
      specialties = (specialties || []).sort((s1, s2) => {
        const s1Date = new Date(s1.created_at);
        const s2Date = new Date(s2.created_at);

        return s1Date.getTime() > s2Date.getTime() ? -1 : 1;
      });
    } else {
      specialties = (specialties || []).sort((s1, s2) => {
        return removeAccentuation(s1.name) > removeAccentuation(s2.name)
          ? -1
          : 1;
      });
    }

    return specialties;
  }, [
    contentsAndSpecialtiesInProgressOrFinished?.finished?.specialties,
    page,
    selectedOrder,
    showing,
  ]);

  const numberOfFinishedSpecialties = useMemo(() => {
    return (
      contentsAndSpecialtiesInProgressOrFinished?.finished?.specialties
        ?.length || 0
    );
  }, [
    contentsAndSpecialtiesInProgressOrFinished?.finished?.specialties?.length,
  ]);

  const numberOfFinishedContents = useMemo(() => {
    return (
      contentsAndSpecialtiesInProgressOrFinished?.finished?.contents?.length ||
      0
    );
  }, [contentsAndSpecialtiesInProgressOrFinished?.finished?.contents?.length]);

  const numberOfFinishedContentsOrSpecialties = useMemo(() => {
    return numberOfFinishedSpecialties + numberOfFinishedContents;
  }, [numberOfFinishedContents, numberOfFinishedSpecialties]);

  useEffect(() => {
    getContentsAndSpecialtiesInProgressOrFinished();
  }, [getContentsAndSpecialtiesInProgressOrFinished]);

  useEffect(() => {
    if (showingContent) {
      setShowing(showingContent as ShowingContent);
    } else {
      setShowing('both');
    }
  }, [showingContent]);

  return (
    <MyProgressContainer>
      <SubHeader background={partner?.banner?.myprogress || progressBanner}>
        <Breadcrumb
          crumbs={[
            <PartnerLink className="home" to="/home">
              Início
            </PartnerLink>,
            <PartnerLink to="/my-list">Minha Lista</PartnerLink>,
          ]}
        />

        <SubHeaderDivider />

        <GoBack />

        <SubHeaderTitle title={'Meu Progresso'} />
      </SubHeader>

      <InProgressContainer className="max-content">
        {showing === 'both' && (
          <InProgressTitle>
            Em andamento ({numberOfInProgressContentsOrSpecialties})
          </InProgressTitle>
        )}

        {(showing === 'both' || showing === 'contentsInProgress') &&
          !!contentsInProgress.length && (
            <ContentsContainer>
              <TitleAndOrderOrShowMoreContainer>
                <ContentsTitle>Vídeos</ContentsTitle>

                {showing === 'both' ? (
                  <ShowMoreButton
                    type="button"
                    onClick={() => handleChangeShowing('contentsInProgress')}
                  >
                    <span>Ver Mais</span>{' '}
                    <AiOutlineRight color="(--primary-color)" />
                  </ShowMoreButton>
                ) : (
                  <Select
                    label="Classificar por"
                    options={orderOptions}
                    value={orderOptions.find((o) => o.value === selectedOrder)}
                    setValue={handleChangeOrder}
                  />
                )}
              </TitleAndOrderOrShowMoreContainer>

              {!!contentsInProgress.length &&
                contentsInProgress.map((c) => (
                  <VideoCard key={c.content_id} video={c} inProgress />
                ))}

              {numberOfInProgressContents >
                numberOfContentsOrSpecialtiesPerPage &&
                showing === 'contentsInProgress' && (
                  <PaginationContainer>
                    <Pagination
                      pageCount={Math.ceil(
                        numberOfInProgressContents /
                          numberOfContentsOrSpecialtiesPerPage
                      )}
                      onPageChange={handleChangePage}
                    />
                  </PaginationContainer>
                )}
            </ContentsContainer>
          )}

        {(showing === 'both' || showing === 'specialtiesInProgress') &&
          !!specialtiesInProgress.length && (
            <SpecialtiesContainer>
              <TitleAndOrderOrShowMoreContainer>
                <SpecialtiesTitle>Especialidades</SpecialtiesTitle>

                {showing === 'both' ? (
                  <ShowMoreButton
                    type="button"
                    onClick={() => handleChangeShowing('specialtiesInProgress')}
                  >
                    <span>Ver Mais</span>{' '}
                    <AiOutlineRight color="(--primary-color)" />
                  </ShowMoreButton>
                ) : (
                  <Select
                    label="Classificar por"
                    options={orderOptions}
                    value={orderOptions.find((o) => o.value === selectedOrder)}
                    setValue={handleChangeOrder}
                  />
                )}
              </TitleAndOrderOrShowMoreContainer>

              {!!specialtiesInProgress.length &&
                specialtiesInProgress.map((s) => (
                  <SpecialtyCard
                    key={s.content_id}
                    specialty={s}
                    inProgress
                    shouldGetFullSpecialty={false}
                  />
                ))}

              {numberOfInProgressSpecialties >
                numberOfContentsOrSpecialtiesPerPage &&
                showing === 'specialtiesInProgress' && (
                  <PaginationContainer>
                    <Pagination
                      pageCount={Math.ceil(
                        numberOfInProgressSpecialties /
                          numberOfContentsOrSpecialtiesPerPage
                      )}
                      onPageChange={handleChangePage}
                    />
                  </PaginationContainer>
                )}
            </SpecialtiesContainer>
          )}
      </InProgressContainer>

      {showing === 'both' && (
        <InProgressAndFinishedDivider className="max-content" />
      )}

      <FinishedContainer className="max-content">
        {showing === 'both' && (
          <FinishedTitle>
            Concluídos ({numberOfFinishedContentsOrSpecialties})
          </FinishedTitle>
        )}

        {(showing === 'both' || showing === 'contentsFinished') &&
          !!contentsFinished.length && (
            <ContentsContainer>
              <TitleAndOrderOrShowMoreContainer>
                <ContentsTitle>Vídeos</ContentsTitle>

                {showing === 'both' ? (
                  <ShowMoreButton
                    type="button"
                    onClick={() => handleChangeShowing('contentsFinished')}
                  >
                    <span>Ver Mais</span>{' '}
                    <AiOutlineRight color="(--primary-color)" />
                  </ShowMoreButton>
                ) : (
                  <Select
                    label="Classificar por"
                    options={orderOptions}
                    value={orderOptions.find((o) => o.value === selectedOrder)}
                    setValue={handleChangeOrder}
                  />
                )}
              </TitleAndOrderOrShowMoreContainer>

              {!!contentsFinished.length &&
                contentsFinished.map((c) => (
                  <VideoCard key={c.content_id} video={c} isFinished />
                ))}

              {numberOfFinishedContents >
                numberOfContentsOrSpecialtiesPerPage && (
                <PaginationContainer>
                  <Pagination
                    pageCount={Math.ceil(
                      numberOfFinishedContents /
                        numberOfContentsOrSpecialtiesPerPage
                    )}
                    onPageChange={handleChangePage}
                  />
                </PaginationContainer>
              )}
            </ContentsContainer>
          )}

        {(showing === 'both' || showing === 'specialtiesFinished') &&
          !!specialtiesFinished.length && (
            <SpecialtiesContainer>
              <TitleAndOrderOrShowMoreContainer>
                <SpecialtiesTitle>Especialidades</SpecialtiesTitle>

                {showing === 'both' ? (
                  <ShowMoreButton
                    type="button"
                    onClick={() => handleChangeShowing('specialtiesFinished')}
                  >
                    <span>Ver Mais</span>{' '}
                    <AiOutlineRight color="(--primary-color)" />
                  </ShowMoreButton>
                ) : (
                  <Select
                    label="Classificar por"
                    options={orderOptions}
                    value={orderOptions.find((o) => o.value === selectedOrder)}
                    setValue={handleChangeOrder}
                  />
                )}
              </TitleAndOrderOrShowMoreContainer>

              {!!specialtiesFinished.length &&
                specialtiesFinished.map((s) => (
                  <SpecialtyCard
                    key={s.content_id}
                    specialty={s}
                    isFinished
                    shouldGetFullSpecialty={false}
                  />
                ))}

              {numberOfFinishedSpecialties >
                numberOfContentsOrSpecialtiesPerPage && (
                <PaginationContainer>
                  <Pagination
                    pageCount={Math.ceil(
                      numberOfFinishedSpecialties /
                        numberOfContentsOrSpecialtiesPerPage
                    )}
                    onPageChange={handleChangePage}
                  />
                </PaginationContainer>
              )}
            </SpecialtiesContainer>
          )}
      </FinishedContainer>
    </MyProgressContainer>
  );
};

export default MyProgress;

import React, { useCallback, useState } from 'react';
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import { BiUserCircle } from 'react-icons/bi';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { useLocation } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import useMobileMenu from 'src/hooks/useMobileMenu';
import usePartner from 'src/hooks/usePartner';
import useSearch from 'src/hooks/useSearch';
import {
  MobileMenuCloseButton,
  MobileMenuContainer,
  MobileMenuContent,
  MobileMenuItem,
  MobileMenuList,
  MobileMenuSearchButton,
  MobileMenuSearchContainer,
  MobileMenuSearchInput,
  MobileMenuUserContextMenuContainer,
  MobileMenuUserContextMenuContent,
  MobileMenuUserContextMenuOpenButton,
  MobileMenuDivider,
} from './style';

const MobileMenu: React.FC = () => {
  const { navigate } = usePartner();
  const { opened, setOpened } = useMobileMenu();
  const { pathname } = useLocation();

  const handleCloseMobileMenu = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  const handleNavigate = useCallback(
    (url: string) => {
      navigate(url);

      handleCloseMobileMenu();
    },
    [handleCloseMobileMenu, navigate]
  );

  return opened ? (
    <MobileMenuContainer>
      <MobileMenuCloseButton type="button" onClick={handleCloseMobileMenu}>
        <AiOutlineClose color="#ddd" size={32} />
      </MobileMenuCloseButton>
      <MobileMenuContent>
        <MobileMenuSearch />

        <MobileMenuDivider />

        <MobileMenuUserContextMenu />

        <MobileMenuDivider />

        <MobileMenuList>
          <MobileMenuItem
            type="button"
            active={pathname.includes('/home')}
            onClick={() => handleNavigate('/home')}
          >
            Inicio
          </MobileMenuItem>
          <MobileMenuItem
            onClick={() => handleNavigate('/specialties')}
            active={
              pathname.includes('/specialties') &&
              !pathname.includes('/my-list')
            }
          >
            Especialidades
          </MobileMenuItem>
          <MobileMenuItem
            onClick={() => handleNavigate('/my-list')}
            active={pathname.includes('/my-list')}
          >
            Minha Lista
          </MobileMenuItem>
        </MobileMenuList>
      </MobileMenuContent>
    </MobileMenuContainer>
  ) : (
    <></>
  );
};

const MobileMenuSearch: React.FC = () => {
  const { searchText, setSearchText } = useSearch();
  const [search, setSearch] = useState(searchText);
  const { setOpened } = useMobileMenu();

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      setSearchText(search);

      setOpened(false);
    },
    [search, setOpened, setSearchText]
  );

  return (
    <MobileMenuSearchContainer onSubmit={handleSubmit}>
      <MobileMenuSearchInput
        type="search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <MobileMenuSearchButton>
        <AiOutlineSearch size={20} />
      </MobileMenuSearchButton>
    </MobileMenuSearchContainer>
  );
};

const MobileMenuUserContextMenu: React.FC = () => {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { navigate } = usePartner();
  const { setOpened: setMobileMenuOpened } = useMobileMenu();
  const [opened, setOpened] = useState(false);

  const handleNavigate = useCallback(
    (url: string) => {
      navigate(url);

      setMobileMenuOpened(false);
    },
    [navigate, setMobileMenuOpened]
  );

  const toggleOpened = useCallback(() => {
    setOpened(!opened);
  }, [opened, setOpened]);

  return (
    <MobileMenuUserContextMenuContainer>
      <MobileMenuUserContextMenuOpenButton onClick={toggleOpened}>
        <BiUserCircle size={20} />
        <span>{user?.name || 'Desconhecido'}</span>
        {opened ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </MobileMenuUserContextMenuOpenButton>
      <MobileMenuUserContextMenuContent opened={opened}>
        <MobileMenuItem
          active={pathname.includes('/my-progress')}
          onClick={() => handleNavigate('/my-progress')}
        >
          Meu Progresso
        </MobileMenuItem>
        <MobileMenuItem
          active={pathname.includes('/my-list')}
          onClick={() => handleNavigate('/my-list')}
        >
          Minha Lista
        </MobileMenuItem>
        <MobileMenuItem
          active={pathname.includes('/my-profile')}
          onClick={() => handleNavigate('/my-profile')}
        >
          Meu Perfil
        </MobileMenuItem>
      </MobileMenuUserContextMenuContent>
    </MobileMenuUserContextMenuContainer>
  );
};

export default MobileMenu;

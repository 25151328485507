import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  padding-bottom: 48px;
`;

const LoginHeader = styled.div`
  display: flex;
  padding: 8px 0;
`;

const SideAndFormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  flex: 1;
`;

const SideContent = styled.div`
  width: 30%;
  min-width: 300px;
  border-right: solid 1px var(--border-gray);
  padding: 0px 64px;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: calc(70% - 32px);
  min-width: 300px;
`;

const GoToLandingLink = styled(Link)``;

const SideContentText = styled.h3`
  font-size: 3.4em;
  color: var(--secondary-color);
  padding-top: 32px;

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const LoginFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    font-size: 0.8em;
    color: var(--secondary-color);
    font-weight: bold;
    padding-left: 8px;
  }
`;

const LoginInput = styled.input`
  border-radius: 30px;
  border: solid 1px var(--border-gray);
  min-width: 300px;
  height: 40px;
  padding: 8px 16px;

  &:focus {
    outline-color: var(--primary-color);
  }
`;

const LoginFormButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 300px;
`;

const LoginButton = styled.button`
  background: var(--primary-color);
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 48px;
  border-radius: 4px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
`;

export {
  LoginContainer,
  LoginForm,
  SideContent,
  GoToLandingLink,
  SideContentText,
  LoginFormGroup,
  LoginInput,
  LoginButton,
  SideAndFormContainer,
  LoginHeader,
  LoginFormButtonGroup,
};

import React from 'react';
import styled from 'styled-components';
import Switch from 'react-switch';
import useVideos from 'src/pages/Videos/hooks/useVideos';

const AutoPlayToggleContainer = styled.span`
  display: flex;
  align-items: center;
  max-width: 140px;

  label {
    font-size: 0.75rem;
  }
`;

const AutoPlayToggle: React.FC = () => {
  const { autoPlay, setAutoPlay } = useVideos();

  const handleChange = (checked: boolean) => {
    setAutoPlay(checked);
  };

  return (
    <AutoPlayToggleContainer>
      <label htmlFor="auto-play">Reprodução automática:</label>
      <Switch
        id="auto-play"
        onChange={handleChange}
        checked={autoPlay}
        width={48}
        height={20}
      />
    </AutoPlayToggleContainer>
  );
};

export default AutoPlayToggle;

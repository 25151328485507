import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: var(--secondary-color);
  padding: 48px;
  width: 100%;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--white);
  gap: 50px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .socialMedias {
    display: flex;
    gap: 16px;
  }

  .rightSideElements {
    display: flex;
    flex-direction: row;
    gap: 64px;
  }

  @media (max-width: 1000px) {
    flex-wrap: wrap;

    & > div {
      flex-wrap: wrap;
      gap: 24px;
    }

    .rightSideElements {
      flex-wrap: wrap;
      gap: 24px;
      text-decoration: underline;
    }
  }
`;

export { FooterContainer, FooterContent };

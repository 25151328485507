import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IoMdAdd, IoMdPlay } from 'react-icons/io';
import clamp from 'clamp-js';
import goToTop from 'src/helpers/go-to-top';
import showErrorMessage from 'src/helpers/showErrorMessage';
import showSuccessMessage from 'src/helpers/showSuccessMessage';
import usePartner from 'src/hooks/usePartner';
import useThemes from 'src/hooks/useThemes';
import Content from 'src/models/content';
import SystemError from 'src/models/error';
import { addToListContent } from 'src/services/contents';
import { StyleDefaultButton } from '../StyleDefaultButton/styles';
import {
  Container,
  ContainerContentBanner,
  ContainerTheme,
  ContentBanner,
} from './style';
import { AiOutlineCheckCircle } from 'react-icons/ai';

interface HighlightVideoSlideProps {
  content: Content;
}

const HighlightVideoSlide: React.FC<HighlightVideoSlideProps> = ({
  content: initialContent,
}) => {
  const { themes } = useThemes();
  const [content, setContent] = useState({} as Content);
  const { navigate } = usePartner();
  const title = useRef<HTMLHeadingElement>(null);
  const description = useRef<HTMLParagraphElement>(null);

  const goToContent = useCallback(async () => {
    navigate(`/videos/content/${content.content_id}`);
    goToTop();
  }, [navigate, content.content_id]);

  const contentThemes = useMemo(() => {
    return (themes || []).filter((t) => {
      return (t.children || [])
        .map((c) => c.content_id)
        .includes(content.content_id);
    });
  }, [themes, content.content_id]);

  const addedToList = useMemo(() => {
    return content?.content_user?.book_mark;
  }, [content]);

  const handleAddToList = useCallback(async () => {
    if (content?.content_id) {
      try {
        const shouldAddToList = !content.content_user?.book_mark;

        await addToListContent(content?.content_id, shouldAddToList);

        showSuccessMessage(
          `Vídeo ${
            shouldAddToList ? 'adicionado ' : 'removido d'
          }a lista de favoritos!`
        );

        if (content.content_user) {
          content.content_user.book_mark = shouldAddToList;
        }

        setContent({ ...(content || {}) });
      } catch (error) {
        showErrorMessage(error as SystemError);
      }
    }
  }, [content]);

  useEffect(() => {
    if (title.current) {
      clamp(title.current, { clamp: 2 });
    }
  }, []);

  useEffect(() => {
    if (description.current) {
      clamp(description.current, { clamp: 3 });
    }
  }, []);

  useEffect(() => {
    if (initialContent) {
      setContent(initialContent);
    }
  }, [initialContent]);

  return (
    <Container>
      <img src={content.images?.thumbnail} alt={content.name} />
      <ContainerContentBanner className="max-content">
        <ContentBanner>
          <ContainerTheme>
            {contentThemes.length
              ? contentThemes.map((theme) => {
                  return <span key={theme.content_id}>{theme.name}</span>;
                })
              : null}
          </ContainerTheme>
          <h1 ref={title}>{content.name}</h1>
          <p ref={description}>{content.description}</p>
          <div>
            <StyleDefaultButton widthValue="214px" onClick={goToContent}>
              <IoMdPlay />
              assistir
            </StyleDefaultButton>
            <StyleDefaultButton
              isOutline
              widthValue="214px"
              onClick={handleAddToList}
            >
              {addedToList ? (
                <>
                  <AiOutlineCheckCircle /> <span>Adicionado</span>
                </>
              ) : (
                <>
                  <IoMdAdd /> <span>minha lista</span>
                </>
              )}
            </StyleDefaultButton>
          </div>
        </ContentBanner>
      </ContainerContentBanner>
    </Container>
  );
};

export default HighlightVideoSlide;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Content from 'src/models/content';
import useThemes from 'src/hooks/useThemes';
import {
  ContinueWatching,
  ProgressContainer,
  ReWatch,
  VideoCardContainer,
  VideoDescription,
  VideoTheme,
  VideoThemesContainer,
  VideoThumbnail,
  VideoTitle,
} from './style';
import clamp from 'clamp-js';
import usePartner from 'src/hooks/usePartner';
import { ReactComponent as ProgressPlayIcon } from 'src/assets/progress-play-icon.svg';
import { ReactComponent as ReWatchIcon } from 'src/assets/re-watch-icon.svg';

interface VideoCardProps {
  video: Content;
  inProgress?: boolean;
  isFinished?: boolean;
}

const VideoCard: React.FC<VideoCardProps> = ({
  video,
  inProgress,
  isFinished,
}) => {
  const [hovered, setHovered] = useState(false);
  const { themes } = useThemes();
  const videoTitleRef = useRef<HTMLHeadingElement>(null);
  const { navigate } = usePartner();

  const goToVideo = useCallback(async () => {
    navigate(`/videos/content/${video.content_id}`);
  }, [navigate, video.content_id]);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const videoThemes = useMemo(() => {
    return (themes || []).filter((t) => {
      return (t.children || [])
        .map((c) => c.content_id)
        .includes(video.content_id);
    });
  }, [themes, video.content_id]);

  useEffect(() => {
    if (videoTitleRef.current) {
      clamp(videoTitleRef.current, { clamp: 2 });
    }
  }, []);

  return (
    <VideoCardContainer
      className="video-card-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={goToVideo}
    >
      <VideoThumbnail src={video.images.thumbnail} hovered={hovered} />
      <VideoThemesContainer>
        {!!videoThemes.length &&
          videoThemes.map((vt) => (
            <VideoTheme key={vt.content_id}>{vt.name}</VideoTheme>
          ))}
      </VideoThemesContainer>
      <VideoTitle ref={videoTitleRef} hovered={hovered}>
        {video.name}
      </VideoTitle>
      {hovered &&
        (inProgress || isFinished ? (
          inProgress ? (
            <ContinueWatching>
              <span className="icon">
                <ProgressPlayIcon />
              </span>
              <span className="text">Continue assistindo</span>{' '}
              <span className="percent">
                {Math.floor(video.progress || 0)}%
              </span>
            </ContinueWatching>
          ) : isFinished ? (
            <ReWatch>
              <span className="icon">
                <ReWatchIcon />
              </span>
              <span className="text">Reassistir</span>
              <span className="percent">Concluído</span>
            </ReWatch>
          ) : (
            <></>
          )
        ) : (
          <VideoDescription>
            {(video.description || '').slice(0, 117) +
              ((video.description || '').length > 117 ? '...' : '')}
          </VideoDescription>
        ))}

      {inProgress && (
        <ProgressContainer progress={video.progress || 0}>
          <span className="progress"></span>
        </ProgressContainer>
      )}

      {isFinished && (
        <ProgressContainer progress={100}>
          <span className="progress"></span>
        </ProgressContainer>
      )}
    </VideoCardContainer>
  );
};

export default VideoCard;

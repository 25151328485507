import axios from 'axios';
const urlComplement = window.localStorage.getItem('manole-partner');

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

httpClient.interceptors.request.use((config) => {
  if (config.headers) {
    const token = window.localStorage.getItem('manole-api-token');
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

httpClient.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error?.response.status === 401 || error?.response.status === 403) {
      if (
        !window.location.href.endsWith('/accessDenied') &&
        !window.location.href.endsWith('/login')
      ) {
        if (
          !(window.location.pathname === '/' || window.location.pathname === '')
        ) {
          window.location.href = `${window.location.origin}/${urlComplement}/accessDenied`;
        }
      }
    }
    if (error?.response.status === 500) {
      if (!window.location.href.endsWith('/somethingWrong')) {
        window.location.href = `${window.location.origin}/${urlComplement}/somethingWrong`;
      }
    }
    return Promise.reject(error);
  }
);

export default httpClient;

import React, { useEffect, useRef, useState } from 'react';
import useVideos from 'src/pages/Videos/hooks/useVideos';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ReactComponent as DownArrow } from 'src/assets/down-arrow.svg';
import { ReactComponent as UpArrow } from 'src/assets/up-arrow.svg';
import ExtraMaterial from 'src/models/extra-material';
import clamp from 'clamp-js';
import { BsDownload } from 'react-icons/bs';

const ContentTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExtraMaterials = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DescriptionContainer = styled.div`
  p {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }

  button {
    border: none;
    outline: none;
    background: none;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    margin-top: 16px;
  }
`;

const NoExtraMaterialMessage = styled.p``;

interface DescriptionProps {
  description: string | undefined;
}

const Description: React.FC<DescriptionProps> = ({ description }) => {
  const [showingAll, setShowingAll] = useState(false);
  const [showShowingAllButton, setShowShowingAllButton] = useState(false);
  const descriptionParagraph = useRef<HTMLParagraphElement>(null);

  const showMore = () => {
    setShowingAll(true);
  };

  const showLess = () => {
    setShowingAll(false);
  };

  useEffect(() => {
    if (descriptionParagraph.current) {
      const hasMoreThan1100chars =
        (descriptionParagraph.current.innerText || '').length > 1100;
      if (hasMoreThan1100chars) {
        setShowShowingAllButton(true);
      } else {
        setShowShowingAllButton(false);
      }

      if (showingAll) {
        clamp(descriptionParagraph.current, { clamp: Number.MAX_SAFE_INTEGER });
      } else {
        clamp(descriptionParagraph.current, { clamp: 8 });
      }
    }
  }, [descriptionParagraph, showingAll]);

  return (
    <DescriptionContainer>
      <p ref={descriptionParagraph}>{description}</p>
      {showShowingAllButton && (
        <>
          {showingAll ? (
            <button type="button" className="show-less" onClick={showLess}>
              <span>Ver menos</span>
              <UpArrow />
            </button>
          ) : (
            <button type="button" className="show-more" onClick={showMore}>
              <span>Ver mais</span>
              <DownArrow />
            </button>
          )}
        </>
      )}
    </DescriptionContainer>
  );
};

const ExtraMaterialCardContainer = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  border: solid 1px var(--gray-darker);
  text-decoration: none;
  color: var(--gray-darken);

  :hover {
    background: var(--hover);
  }
`;

interface ExtraMaterialCardProps {
  extraMaterial: ExtraMaterial;
}

const ExtraMaterialCard: React.FC<ExtraMaterialCardProps> = ({
  extraMaterial,
}) => {
  return (
    <ExtraMaterialCardContainer
      download
      target="_blank"
      href={extraMaterial.reference}
    >
      <span className="title">{extraMaterial.title}</span>
      <BsDownload />
    </ExtraMaterialCardContainer>
  );
};

const ContentTabs: React.FC = () => {
  const { specialty, selectedContent } = useVideos();

  return (
    <ContentTabsContainer>
      <Tabs>
        <TabList>
          <Tab>Descrição</Tab>
          {!!selectedContent && <Tab>Material complementar</Tab>}
        </TabList>

        <TabPanel>
          <Description
            description={selectedContent?.description || specialty?.description}
          />
        </TabPanel>
        {!!selectedContent && (
          <TabPanel>
            <ExtraMaterials>
              {selectedContent.extra_materials?.length ? (
                selectedContent.extra_materials.map((em) => (
                  <ExtraMaterialCard
                    key={em.extra_material_id}
                    extraMaterial={em}
                  />
                ))
              ) : (
                <NoExtraMaterialMessage>
                  Não foram encontrados materiais complementares para este
                  vídeo.
                </NoExtraMaterialMessage>
              )}
            </ExtraMaterials>
          </TabPanel>
        )}
      </Tabs>
    </ContentTabsContainer>
  );
};

export default ContentTabs;

import React, { useCallback, useEffect, useState } from 'react';
import AuthorCard from 'src/components/AuthorCard';
import PlanCard from 'src/components/PlanCard';
import Carousel from 'src/components/LandingCarousel';
import devices from 'src/assets/devices.png';
import google from 'src/assets/googlePlay.svg';
import apple from 'src/assets/appStore.svg';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import {
  GlobalStyles,
  LandingHeader,
  Button,
  LandingPageContainer,
  Hero,
  SpecialistConatiner,
  SignContainer,
  MostViewedConatiner,
  DevicesContainer,
} from './style';
import Content from 'src/models/content';
import Authors from 'src/models/author';
import {
  getLandingContents as getContentsService,
  getLandingAuthors as getAuthorsService,
} from 'src/services/landing';
import CarouselContent from 'src/components/LandingCarousel/Content';
import { useNavigate } from 'react-router-dom';
import PlanText from 'src/components/PlanText';
import planInfo from './planMock';
import useLoading from 'src/hooks/useLoading';

const LandingPage: React.FC = () => {
  const [landingContent, setLandingContent] = useState<Content[] | undefined>();
  const [authorsContent, setAuthorsContent] = useState<Authors[] | undefined>();
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();

  const getContent = useCallback(async () => {
    const { data: content } = await getContentsService();
    const filterdContent = content.filter((c) => c.flag === 'content');
    setLandingContent(filterdContent);
  }, []);

  const getAuthors = useCallback(async () => {
    const authors = await getAuthorsService();
    const filteredAuthors = (authors || []).filter(
      (a) => a?.info?.is_highlight
    );
    setAuthorsContent(filteredAuthors.splice(0, 3));
  }, []);

  useEffect(() => {
    setIsLoading(true);

    getContent();
    getAuthors();

    setIsLoading(false);
  }, [getAuthors, getContent, setIsLoading]);

  return (
    <LandingPageContainer>
      <GlobalStyles />
      <LandingHeader>
        <Logo />
        <Button header onClick={() => navigate('/login')}>
          já tenho conta
        </Button>
      </LandingHeader>
      <Hero>
        <div className="leftSideHero max-content">
          <h1>
            Conteúdo médico para o seu <span>crescimeto profissional.</span>
          </h1>
          <p>Assista onde e quando quiser.</p>
          <Button onClick={() => navigate('/sign-up')}>assine agora</Button>
        </div>
      </Hero>
      <PlanText />
      <SpecialistConatiner className="max-content">
        <h1>Especialistas Internacionais</h1>
        <div>
          {authorsContent?.length
            ? authorsContent?.map((author) => (
                <AuthorCard key={author.author_id} author={author} />
              ))
            : []}
        </div>
      </SpecialistConatiner>
      <SignContainer className="max-content">
        <h1>Assine já e aproveite todo nosso conteúdo.</h1>
        <div>
          {planInfo.length
            ? planInfo.map((plan) => (
                <PlanCard
                  key={plan?.id}
                  planType={plan?.title}
                  price1={plan?.price1}
                  price2={plan?.price2}
                  text={plan?.text}
                  liText={plan?.liText}
                  buttonText={plan?.buttonText}
                  path={plan?.path}
                />
              ))
            : []}
        </div>
      </SignContainer>
      <MostViewedConatiner>
        <div className="max-content">
          <h1>Mais acessados</h1>
          <Carousel
            items={
              landingContent?.length
                ? landingContent?.map((c) => {
                    return (
                      <CarouselContent key={c.content_id} landingContent={c} />
                    );
                  })
                : []
            }
          />
        </div>
      </MostViewedConatiner>
      <DevicesContainer>
        <div className="max-content">
          <img src={devices} alt="Devices" />
          <div className="rightSide">
            <p>Assita no notebook, smartphone e acesse em qualquer lugar</p>
            <div className="store">
              <img src={google} alt="Play Store" />
              <img src={apple} alt="Apple Store" />
            </div>
            <Button type="button" onClick={() => navigate('/sign-up')}>
              assine agora
            </Button>
          </div>
        </div>
      </DevicesContainer>
    </LandingPageContainer>
  );
};

export default LandingPage;

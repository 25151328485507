import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import SubHeader from 'src/components/SubHeader';
import Specialty from 'src/models/specialty';
import {
  SpecialtyContainer,
  SpecialtyContentsContainer,
  SpecialtyThemesContainer,
  SubHeaderSpecialtyInfo,
} from './style';
import { getSpecialty as getSpecialtyService } from 'src/services/specialties';
import Breadcrumb from 'src/components/Breadcrumb';
import usePartner from 'src/hooks/usePartner';
import Content from 'src/models/content';
import Button from 'src/components/Button';
import { ReactComponent as PlayIcon } from 'src/assets/play-icon.svg';
import { ReactComponent as AddIcon } from 'src/assets/add-icon.svg';
import specialtyDefaultImage from 'src/assets/specialty-default-image.png';
import showErrorMessage from 'src/helpers/showErrorMessage';
import SystemError from 'src/models/error';
import { addToListSpecialty as addToListSpecialtyService } from 'src/services/specialties';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import showSuccessMessage from 'src/helpers/showSuccessMessage';
import VideoCard from 'src/components/VideoCard';
import SelectOption from 'src/models/select-option';
import ThemeCard from 'src/components/ThemeCard';
import Select from 'src/components/Select';
import { SubHeaderDivider } from 'src/components/SubHeaderDivider';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import removeAccentuation from 'src/helpers/remove-accentuation';
import useLoading from 'src/hooks/useLoading';
import useWindowSize from 'src/hooks/useWindowSize';

const orderOptions = [
  { label: 'Mais recentes', value: 'most_recent' },
  { label: 'Ordem alfabética', value: 'alphabetic' },
];

const SpecialtyPage: React.FC = () => {
  const { windowWidth } = useWindowSize();
  const { specialtyId } = useParams<'specialtyId'>();
  const { PartnerLink, navigate } = usePartner();
  const { setIsLoading } = useLoading();
  const [specialty, setSpecialty] = useState<Specialty | undefined>();
  const [selectedOrder, setSelectedOrder] = useState<SelectOption | undefined>(
    orderOptions.find((o) => o.value === 'most_recent')
  );

  const getSpecialty = useCallback(async () => {
    if (specialtyId) {
      setIsLoading(true);

      const specialty = await getSpecialtyService(specialtyId);
      setSpecialty(specialty);

      setIsLoading(false);
    }
  }, [setIsLoading, specialtyId]);

  const watchAllContents = useCallback(() => {
    if (specialty?.content_id) {
      navigate(`/videos/specialty/${specialty.content_id}`);
    }
  }, [navigate, specialty?.content_id]);

  const bookmarkSpecialty = useCallback(async () => {
    if (specialty?.content_id) {
      try {
        const shouldAddToList = !specialty.content_user?.book_mark;
        await addToListSpecialtyService(specialty.content_id, shouldAddToList);

        showSuccessMessage(
          `Especialidade ${
            shouldAddToList ? 'adicionada ' : 'removida d'
          }a lista de favoritos!`
        );

        await getSpecialty();
      } catch (error) {
        showErrorMessage(error as SystemError);
      }
    }
  }, [getSpecialty, specialty]);

  useEffect(() => {
    getSpecialty();
  }, [getSpecialty]);

  const themes = useMemo(() => {
    return specialty?.children || [];
  }, [specialty?.children]);

  const contents = useMemo(() => {
    const allContents =
      themes
        .filter((t) => !!t && t != null)
        .map((t) => t.children || [])
        .flat() || [];

    let allUniqueContents = [] as Content[];
    for (let content of allContents) {
      if (
        !allUniqueContents.map((c) => c.content_id).includes(content.content_id)
      ) {
        allUniqueContents.push(content);
      }
    }

    switch (selectedOrder?.value) {
      case 'alphabetic':
        allUniqueContents = allUniqueContents.sort((c1, c2) =>
          removeAccentuation(c1.name) > removeAccentuation(c2.name) ? 1 : -1
        );
        break;

      case 'most_recent':
        allUniqueContents = allUniqueContents.sort((c1, c2) =>
          c1.created_at &&
          c2.created_at &&
          new Date(c1.created_at).getTime() > new Date(c2.created_at).getTime()
            ? 1
            : -1
        );
        break;
    }

    return allUniqueContents;
  }, [selectedOrder?.value, themes]);

  const bookmarked = useMemo(() => {
    return !!specialty?.content_user?.book_mark;
  }, [specialty]);

  const themeCarrousselSlidesPerView = useMemo(() => {
    if (windowWidth > 1200) {
      return 6.3;
    } else if (windowWidth > 1000) {
      return 4.8;
    } else if (windowWidth > 768) {
      return 3.8;
    } else if (windowWidth > 560) {
      return 2.6;
    } else {
      return 1.8;
    }
  }, [windowWidth]);

  return (
    <SpecialtyContainer>
      <SubHeader
        background={specialty?.images?.banner || specialtyDefaultImage}
      >
        <Breadcrumb
          crumbs={[
            <PartnerLink to="/home">Início</PartnerLink>,
            <PartnerLink to="/specialties">Especialidades</PartnerLink>,
            <PartnerLink>{specialty?.name}</PartnerLink>,
          ]}
        />
        <SubHeaderDivider />
        <SubHeaderSpecialtyInfo>
          <div className="themes-and-videos">
            {`${themes.length} TEMA${themes.length > 1 ? 'S' : ''} `}{' '}
            <span className="separator">•</span>{' '}
            {` ${contents.length} VÍDEO${contents.length > 1 ? 'S' : ''}`}
          </div>
          <div className="title">{specialty?.name}</div>
          <div className="watch-and-bookmark">
            <Button
              type="button"
              className="watch-all"
              onClick={watchAllContents}
            >
              <PlayIcon />
              <span>ASSISTIR TUDO</span>
            </Button>
            <Button
              type="button"
              className="bookmark"
              onClick={bookmarkSpecialty}
            >
              {!bookmarked ? (
                <>
                  <AddIcon />
                  <span>MINHA LISTA</span>
                </>
              ) : (
                <>
                  <AiOutlineCheckCircle size={16} />
                  <span>ADICIONADO</span>
                </>
              )}
            </Button>
          </div>
          <div className="plan-info">
            Faça já sua assinatura e você terá acesso a todo o conteúdo dessa
            especialidade.
          </div>
        </SubHeaderSpecialtyInfo>
      </SubHeader>
      <SpecialtyThemesContainer className="max-content">
        <h3 className="title">Assista por tema</h3>

        <Swiper
          slidesPerView={themeCarrousselSlidesPerView}
          pagination={{ clickable: true }}
          style={{ width: '100%' }}
        >
          {!!themes.length &&
            themes.map((t) => (
              <SwiperSlide key={t.content_id}>
                <ThemeCard specialty={specialty!} theme={t} />
              </SwiperSlide>
            ))}
        </Swiper>
      </SpecialtyThemesContainer>
      <SpecialtyContentsContainer className="max-content">
        <div className="title-and-filter">
          <h3 className="title">Todos os videos</h3>
          <Select
            label="Classificar por"
            options={orderOptions}
            value={selectedOrder}
            setValue={setSelectedOrder}
          />
        </div>

        {!!contents &&
          contents.map((c, index) => <VideoCard key={index} video={c} />)}
      </SpecialtyContentsContainer>
    </SpecialtyContainer>
  );
};

export default SpecialtyPage;

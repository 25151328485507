import React, { useCallback, useEffect, useState } from 'react';
import Content from 'src/models/content';
import { LatestVideosContainer } from './style';
import { getContents as getContentsService } from 'src/services/contents';
import VideoCard from 'src/components/VideoCard';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import DefaultSlider from 'src/components/DefaultSlider';

const LatestVideos: React.FC = () => {
  const [videos, setVideos] = useState([] as Content[]);

  const getLatestVideos = useCallback(async () => {
    const { data: videos } = await getContentsService({
      order_by: 'updated_at',
    });
    setVideos(videos);
  }, []);

  useEffect(() => {
    getLatestVideos();
  }, [getLatestVideos]);

  return (
    <LatestVideosContainer className="max-content">
      {!!videos.length && (
        <DefaultSlider
          title="Últimos lançamentos"
          items={
            videos?.length
              ? videos?.map((content) => {
                  return (
                    <VideoCard
                      key={content.content_id}
                      video={content}
                      inProgress
                    />
                  );
                })
              : []
          }
        />
      )}
    </LatestVideosContainer>
  );
};

export default LatestVideos;

import React from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as Facebook } from '../../assets/facebookIcon.svg';
import { ReactComponent as Instagram } from '../../assets/instagramIcon.svg';
import { ReactComponent as Twitter } from '../../assets/twitterIcon.svg';
import { FooterContainer, FooterContent } from './style';

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <div>
          <Logo />
          <div className="socialMedias">
            <a
              href="https://www.facebook.com/EditoraManole"
              target="_blank"
              rel="noreferrer"
            >
              <Facebook />
            </a>
            <a
              href="https://www.instagram.com/oficialmanole/"
              target="_blank"
              rel="noreferrer"
            >
              <Instagram />
            </a>
            <a
              href="https://twitter.com/editora_manole"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter />
            </a>
          </div>
        </div>
        <div>
          <p>© Manole LTDA - 2021 | Todos os direitos reservados</p>
          <div className="rightSideElements">
            <p>Seja um parceiro Manole Prime</p>
            <p>Termos de Uso</p>
            <p>Política de Privacidade</p>
          </div>
        </div>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;

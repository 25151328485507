import Content from 'src/models/content';
import { getContent as getContentService } from 'src/services/contents';

const getContentProgress = async (content: Content): Promise<Content> => {
  const fullContent = await getContentService(content.content_id);

  const contentView = fullContent.content_user?.content_view || 0;

  const duration = fullContent.duration;

  const progress = (contentView / duration) * 100;

  return { ...fullContent, progress };
};

export default getContentProgress;

import DefaultSlider from 'src/components/DefaultSlider';
import HighlightedVideoSlider from 'src/components/HighlightedVideoSlider';
import VideoCard from 'src/components/VideoCard';
import useHome from 'src/hooks/useHome';
import { HomeContainer, HomeContent } from './style';

const Home = () => {
  const {
    contentsInProgress,
    featuredContents,
    suggestedContents,
    newContents,
  } = useHome();

  return (
    <HomeContainer>
      <HighlightedVideoSlider />

      <HomeContent className="max-content">
        {!!contentsInProgress?.length && (
          <DefaultSlider
            title="Continue assistindo"
            items={
              contentsInProgress?.length
                ? contentsInProgress?.map((content) => {
                    return (
                      <VideoCard
                        key={content.content_id}
                        video={content}
                        inProgress
                      />
                    );
                  })
                : []
            }
          />
        )}

        {!!featuredContents?.length && (
          <DefaultSlider
            title="Destaques"
            items={
              featuredContents?.length
                ? featuredContents.map((content) => {
                    return (
                      <VideoCard key={content.content_id} video={content} />
                    );
                  })
                : []
            }
          />
        )}

        {!!suggestedContents?.length && (
          <DefaultSlider
            title="Sugestões para você"
            items={
              suggestedContents?.length
                ? suggestedContents.map((content) => {
                    return (
                      <VideoCard key={content.content_id} video={content} />
                    );
                  })
                : []
            }
          />
        )}

        {!!newContents?.length && (
          <DefaultSlider
            title="Novidades"
            items={
              newContents?.length
                ? newContents.map((content) => {
                    return (
                      <VideoCard key={content.content_id} video={content} />
                    );
                  })
                : []
            }
          />
        )}
      </HomeContent>
    </HomeContainer>
  );
};

export default Home;

import { createContext } from 'react';
import Partner from 'src/models/partner';

interface IPartnerContext {
  partner: Partner | undefined;
  navigate: (url: string) => void;
  PartnerLink: React.FC<any>;
}

const PartnerContext = createContext({} as IPartnerContext);

export default PartnerContext;

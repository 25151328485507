import Button from 'src/components/Button';
import styled, { css } from 'styled-components';

interface SearchContainerProps {
  opened: boolean;
}

const SearchContainer = styled.div<SearchContainerProps>`
  position: relative;
  width: 300px;
  margin-right: 24px;

  ${(props) =>
    props.opened &&
    css`
      width: 40px;
      & > input {
        padding: 8px !important;
      }

      & > svg,
      path,
      circle {
        stroke: var(--white) !important;
        color: var(--white) !important;
      }
    `}
`;

const SearchInput = styled.input`
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 36px;
  width: 100%;

  border: none;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.2);
  color: var(--white);
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  z-index: 8;

  &::placeholder {
    color: var(--white);
    font-size: 14px;
  }
`;

const SearchButton = styled(Button)`
  position: absolute;
  top: 2px;
  left: 2px;
  justify-content: center;
  align-items: center;
`;

export { SearchContainer, SearchInput, SearchButton };
